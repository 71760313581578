import { useState, useRef, useEffect, useCallback } from "react"
import { ResponsiveBar } from "@nivo/bar"
import { Select, Checkbox, Space, Divider } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { toPng } from "html-to-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from "@fortawesome/free-solid-svg-icons"

const { Option } = Select

const customColorScheme = [
  "#36c3ed",
  "#4a90e2",
  "#50e3c2",
  "#f5a623",
  "#b8e986",
  "#bd10e0",
  "#9013fe",
  "#417505",
  "#7ed321",
  "#8b572a",
]

const CustomTooltip = ({ id, value, color, indexValue }) => (
  <div className="bg-white p-2 rounded-lg">
    <strong style={{ color: "#333" }}>{indexValue}</strong>
    <br />
    <span style={{ color }}>
      {id}: {value}
    </span>
  </div>
)

/**
 * BarChart component for rendering a customizable bar chart.
 * @param {Object} props - The props object.
 * @param {Array<Object>} props.data - The data array for the chart.
 * @param {Array<string>} props.keys - The keys to be used for the chart.
 * @param {Array<string>} props.statisticTypes - The available statistic types.
 * @param {string} props.xAxisLabel - The label for the x-axis.
 * @param {boolean} props.isChart - Flag indicating if the component should render as a chart.
 * @returns {JSX.Element} A bar chart component with customizable options.
 */
const BarChart = ({ data, keys, statisticTypes, xAxisLabel, isChart }) => {
  const [selectedStatistic, setSelectedStatistic] = useState(statisticTypes[0])
  const [chartHeight, setChartHeight] = useState(400)
  const [leftMargin, setLeftMargin] = useState(60)
  const [selectedKeys, setSelectedKeys] = useState(
    keys.filter((key) => key !== "Totals")
  )
  const [selectedCategories, setSelectedCategories] = useState(
    data.map((item) => item.xValue)
  )
  const chartRef = useRef(null)

  const formatTickValue = useCallback(
    (value) => {
      if (
        selectedStatistic.toLowerCase().includes("perc") ||
        selectedStatistic.toLowerCase().includes("percent") ||
        selectedStatistic.toLowerCase().includes("%")
      ) {
        return `${(value * 100).toFixed(2)}%`
      }
      return value.toLocaleString()
    },
    [selectedStatistic]
  )

  useEffect(() => {
    const calculateChartHeight = () => {
      const baseHeight = 400
      const xLabelLength = Math.max(
        ...selectedCategories.map((item) => item.length)
      )
      const xItemCount = selectedCategories.length
      const additionalHeight =
        Math.max(xLabelLength * 2 - 20, 0) + Math.max(xItemCount * 10 - 50, 0)
      return baseHeight + additionalHeight
    }

    setChartHeight(calculateChartHeight())

    // Calculate left margin based on the longest y-axis label
    const longestYLabel = formatTickValue(
      Math.max(
        ...data.flatMap((item) =>
          Object.values(item)
            .filter((val) => typeof val === "object" && val[selectedStatistic])
            .map((val) => val[selectedStatistic])
        )
      )
    )
    const newLeftMargin = Math.max(60, longestYLabel.length * 8 + 40) // 8 pixels per character + 40 pixels for padding
    setLeftMargin(newLeftMargin)
  }, [data, selectedStatistic, selectedCategories, formatTickValue])

  if (!data || data.length === 0 || keys.length === 0) {
    return <div>No data available for the chart</div>
  }

  if (!isChart) {
    return (
      <div>Unable to display this output as a chart. Please view as table.</div>
    )
  }

  const filteredKeys = keys.filter((key) => key !== "Totals")

  const chartData = data
    .filter((item) => selectedCategories.includes(item.xValue))
    .map((item) => {
      const newItem = { xValue: item.xValue }
      selectedKeys.forEach((key) => {
        newItem[key] = item[key][selectedStatistic] || 0
      })
      return newItem
    })

  const handleStatisticChange = (value) => {
    setSelectedStatistic(value)
  }

  const handleKeyChange = (key) => {
    setSelectedKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    )
  }

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    )
  }

  const exportChart = () => {
    if (chartRef.current) {
      toPng(chartRef.current, { quality: 0.95 })
        .then((dataUrl) => {
          const link = document.createElement("a")
          link.download = "chart.png"
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.error("Error exporting chart:", err)
        })
    }
  }

  return (
    <div>
      <Space wrap align="center" style={{ marginBottom: "20px" }}>
        <Select
          style={{ width: 100 }}
          value={selectedStatistic}
          onChange={handleStatisticChange}
        >
          {statisticTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
        <DataQButton type="primary" onClick={exportChart}>
          Export <FontAwesomeIcon className="ml-2" icon={faChartColumn} />
        </DataQButton>
        <Divider type="vertical" />
        {filteredKeys.map((key) => (
          <Checkbox
            key={key}
            checked={selectedKeys.includes(key)}
            onChange={() => handleKeyChange(key)}
          >
            {key}
          </Checkbox>
        ))}
        <Divider type="vertical" />
        {data.map((item) => (
          <Checkbox
            key={item.xValue}
            checked={selectedCategories.includes(item.xValue)}
            onChange={() => handleCategoryChange(item.xValue)}
          >
            {item.xValue}
          </Checkbox>
        ))}
      </Space>
      <div
        style={{
          height: `${chartHeight}px`,
          maxWidth: "100%",
          overflow: "auto",
        }}
        ref={chartRef}
      >
        <ResponsiveBar
          data={chartData}
          keys={selectedKeys}
          indexBy="xValue"
          margin={{ top: 50, right: 130, bottom: 120, left: leftMargin }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id }) =>
            customColorScheme[
              filteredKeys.indexOf(id) % customColorScheme.length
            ]
          }
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: xAxisLabel,
            legendPosition: "middle",
            legendOffset: 80,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: selectedStatistic,
            legendPosition: "middle",
            legendOffset: -leftMargin + 20,
            format: formatTickValue,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#333333",
                },
              },
              legend: {
                text: {
                  fill: "#333333",
                  fontSize: 14,
                },
              },
            },
            legends: {
              text: {
                fill: "#333333",
              },
            },
          }}
          tooltip={({ id, value, color, indexValue }) => (
            <CustomTooltip
              id={id}
              value={formatTickValue(value)}
              color={color}
              indexValue={indexValue}
            />
          )}
        />
      </div>
    </div>
  )
}

export default BarChart

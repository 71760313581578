import axios from "../request"

/**
 * Converts data to Decipher XML format and triggers a download of the resulting XML file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion, including filename.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion or download fails.
 */
export async function convertToDecipherXML(
  token,
  user,
  unit,
  role,
  id,
  options
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // This is important to handle the file download
    }
    const response = await axios.post(
      `/convert-decipher-xml`,
      { id: id, options: options },
      config
    )

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${options.filename}.xml`) // Set the filename
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response
  } catch (error) {
    throw error
  }
}

/**
 * Converts data to a Word document format.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion fails.
 */
export async function convertToWord(token, user, unit, role, id, options) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.post(
      `/convert-word`,
      { id: id, options: options },
      config
    )

    return response
  } catch (error) {
    throw error
  }
}

/**
 * Converts data to HTML format and triggers a download of the resulting HTML file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion.
 * @param {string} filename - The desired filename for the downloaded HTML file.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion or download fails.
 */
export async function downloadHTML(
  token,
  user,
  unit,
  role,
  id,
  options,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // This is important to handle the file download
    }
    const response = await axios.post(
      `/convert-word`,
      { id: id, options: options },
      config
    )

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${filename}.html`) // Set the filename
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response
  } catch (error) {
    throw error
  }
}

export const jsonPretty = (obj, indent) => {
  if (!indent) indent = 2
  return JSON.stringify(
    obj,
    function (k, v) {
      // Check if this is a leaf-object with no child Arrays or Objects:
      if (typeof v === "object" && v !== null) {
        for (var p in v) {
          if (Array.isArray(v[p]) || typeof v[p] === "object") {
            return v
          }
        }
        return JSON.stringify(v)
      }
      return v
    },
    indent
  )
    .replace(/\\/g, "") // Remove escape characters
    .replace(/"\[/g, "[") // Remove quotes around arrays
    .replace(/\]"/g, "]") // Remove quotes around arrays
    .replace(/"\{/g, "{") // Remove quotes around objects
    .replace(/\}"/g, "}") // Remove quotes around objects
}

import useApiCall from "hooks/useApiCall"
import { callAdminApi, fetchUnits } from "api/Superadmin/superadmin"

export const useSuperadmin = () => {
  const [getSequenceUnits, isLoadingSequenceUnits] = useApiCall(fetchUnits)
  const [makeAdminApi, isCallingAdminApi] = useApiCall(callAdminApi)

  return {
    getSequenceUnits,
    isLoadingSequenceUnits,
    makeAdminApi,
    isCallingAdminApi,
  }
}

import useApiCall from "hooks/useApiCall"
import {
  getQueries,
  saveQueryfile,
  deleteQueryfile,
  getQueryfile,
} from "api/Repos/queries"

/**
 * Custom hook for managing project-related API calls and their loading states.
 *
 * @returns {Object} - An object containing functions for various project-related API calls and their loading states.
 */
export const useQueries = () => {
  const [getProjectQueries, isLoadingQueries] = useApiCall(getQueries)
  const [saveQuery, isSavingQuery] = useApiCall(saveQueryfile)
  const [deleteQuery, isDeletingQuery] = useApiCall(deleteQueryfile)
  const [getQuery, isGettingQuery] = useApiCall(getQueryfile)

  return {
    getProjectQueries,
    isLoadingQueries,
    saveQuery,
    isSavingQuery,
    deleteQuery,
    isDeletingQuery,
    getQuery,
    isGettingQuery,
  }
}

import { useState } from "react"
import { Input, Upload, message } from "antd"

import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloud } from "@fortawesome/free-solid-svg-icons"

import { useDocument } from "hooks/useDocument"
import ProgressModal from "components/UI/ProgressModal/ProgressModal"
import { calculateFileUploadDuration } from "utils/calculateFileUploadDuration"

const DocumentsHeader = (props) => {
  const { project, refresh, onSearch } = props
  const { Search } = Input
  const { uploadDocument, isUploading } = useDocument()
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false)
  const [modalDuration, setModalDuration] = useState(10000) // Default duration

  const uploadProps = {
    name: "file",
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const response = await uploadDocument(
          project.id,
          project.Created_by,
          file.name,
          file
        )
        onSuccess(response)
      } catch (error) {
        onError(error)
      }
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        // Dynamically set the modal duration based on the original file size (before compression)
        const dynamicDuration = calculateFileUploadDuration(info.file.size)
        setModalDuration(dynamicDuration)
        setIsLoadingModalVisible(true)
      }
      if (info.file.status === "done") {
        setIsLoadingModalVisible(false)
        message.success(`${info.file.name} file uploaded successfully`)
        refresh()
      } else if (info.file.status === "error") {
        setIsLoadingModalVisible(false)
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <>
      <ProgressModal
        title={"Uploading File..."}
        isLoading={isLoadingModalVisible}
        duration={modalDuration} // Set the modal duration dynamically
      />
      <div className="flex flex-row w-full p-5">
        <Search
          placeholder="Search by file name"
          allowClear
          onSearch={onSearch}
          size="large"
          className="my-auto"
          style={{
            width: 300,
          }}
        />
        <Upload showUploadList={false} {...uploadProps} className="ml-auto">
          <DataQButton type="primary" loading={isUploading}>
            Upload Documents
            <FontAwesomeIcon icon={faCloud} className="ml-2" />
          </DataQButton>
        </Upload>
      </div>
    </>
  )
}

export default DocumentsHeader

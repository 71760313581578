import axios from "../request"

/**
 * Fetches the available query files for a given project.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @returns {Promise<Object>} The API response containing the list of available query files.
 * @throws Will throw an error if the API call fails.
 */
export async function getQueries(token, user, unit, role, id, owner) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(
      `/available-queryfiles?id=${id}&hint_owner=${owner}`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Saves a query file for a given project.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be saved.
 * @param {boolean} overwrite - Whether to overwrite the file if it exists.
 * @param {string} content - The content of the query file to be saved.
 * @returns {Promise<Object>} The API response after saving the query file.
 * @throws Will throw an error if the API call fails.
 */
export async function saveQueryfile(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename,
  overwrite,
  content
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    // Encode content into binary using TextEncoder
    const encoder = new TextEncoder()
    const binaryContent = encoder.encode(content)

    const response = await axios.post(
      `/queryfile-save?id=${id}&hint_owner=${owner}&name=${filename}&${overwrite}`,
      binaryContent,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Deletes a query file for a given project.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be deleted.
 * @returns {Promise<Object>} The API response after deleting the query file.
 * @throws Will throw an error if the API call fails.
 */
export async function deleteQueryfile(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.delete(
      `/queryfile-delete?id=${id}&hint_owner=${owner}&name=${filename}`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Fetches the content of a specific query file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be fetched.
 * @returns {Promise<Object>} The API response containing the content of the query file.
 * @throws Will throw an error if the API call fails.
 */
export async function getQueryfile(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(
      `/queryfile-get?id=${id}&hint_owner=${owner}&name=${filename}`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

import axios from "../request"

/**
 * Lists all available template files.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @returns {Promise<Object>} - The response object from the API call containing the list of template files.
 * @throws Will throw an error if the request fails.
 */
export async function listAllTemplateFiles(token, user, unit, role) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(`/template-file-list`, config)
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Lists templates for a selected file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {Object} selectedFile - The selected file object containing template name and type.
 * @returns {Promise<Object>} - The response object from the API call containing the list of templates.
 * @throws Will throw an error if the request fails.
 */
export async function listTemplates(token, user, unit, role, selectedFile) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(
      `/template-get?template=${selectedFile.template}&type=${selectedFile.type}`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Parses a selected template with provided data.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be parsed.
 * @param {Object} selectedFile - The selected file object containing template name and type.
 * @param {Object} template - The template data to be used for parsing.
 * @param {Object} options - Additional options for parsing, including `inMaster`, `qnrEmpty`, and `includeInstructions`.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the parsing fails.
 */
export async function parseTemplate(
  token,
  user,
  unit,
  role,
  id,
  selectedFile,
  template,
  options
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.post(
      `/template-parse?template=${selectedFile.template}&type=${selectedFile.type}`,
      {
        id: id,
        template: template,
        "in-master": options.inMaster,
        "qnr-empty": options.qnrEmpty,
        "include-instructions": true,
      },
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Merges user inputs with a selected template.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {Object} userInputs - The user inputs to be merged with the template.
 * @param {Object} selectedFile - The selected file object containing template name and type.
 * @param {Object} options - Additional options for merging, including `inMaster`, `qnrEmpty`, and `includeInstructions`.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the merge fails.
 */
export async function mergeTemplate(
  token,
  user,
  unit,
  role,
  userInputs,
  selectedFile,
  options
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.post(
      `/template-merge?template=${selectedFile.template}&type=${selectedFile.type}`,
      {
        userInputs: userInputs,
        "in-master": options.inMaster,
        "qnr-empty": options.qnrEmpty,
        "include-instructions": true,
      },
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

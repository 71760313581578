import { useState } from "react"
import { Modal, Input } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { ErrorNotification } from "../Notifications/NotificationTemplate.component"

/**
 * ExportModal Component
 *
 * @param {Object} props - The props object for ExportModal.
 * @param {string} props.title - The title of the modal.
 * @param {boolean} props.isVisible - Controls the visibility of the modal.
 * @param {function} props.cancel - Function to call when the cancel button is clicked.
 * @param {function} props.confirm - Function to call when the export button is clicked, with the input value as an argument.
 * @param {string} [props.ExportButtonText='Export'] - The text displayed on the export button.
 * @param {string} [props.ExportButtonType='secondary'] - The type of the export button.
 * @param {string} props.message - The message displayed above the input field.
 *
 * @returns {JSX.Element} The rendered ExportModal component.
 */
const ExportModal = (props) => {
  const {
    title,
    isVisible,
    cancel,
    confirm,
    ExportButtonText,
    ExportButtonType,
    message,
  } = props

  const [inputValue, setInputValue] = useState("") // state to hold input value

  /**
   * Handles the change in the input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the input field.
   */
  const handleInputChange = (e) => {
    setInputValue(e.target.value) // update state with input value
  }

  /**
   * Handles the confirm action and validates the input value.
   */
  const handleConfirm = () => {
    if (!inputValue.trim()) {
      ErrorNotification("Error Exporting", "Please enter a filename") // show error notification if input is empty
      return
    }
    confirm(inputValue) // pass input value to confirm function
  }

  return (
    <Modal
      centered
      title={title}
      open={isVisible}
      closable={false}
      footer={null}
    >
      <div className="flex flex-col mb-2">
        <p className="text-md">{message}</p>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter filename"
        />{" "}
        {/* controlled input */}
      </div>
      <div className="flex flex-row space-x-2">
        <DataQButton
          className="ml-auto"
          type="secondary"
          onClick={() => cancel(false)}
        >
          Cancel
        </DataQButton>
        <DataQButton
          type={ExportButtonType || "primary"}
          onClick={handleConfirm}
          disabled={!inputValue.trim()} // disable button if input is empty
        >
          {ExportButtonText || "Export"}
        </DataQButton>
      </div>
    </Modal>
  )
}

export default ExportModal

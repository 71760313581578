/**
 * Action mappings for menu items.
 */
const actions = (editor) => ({
  note: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to include notes to the person or group that you specify as the target of the note.</p>" +
        "<p># When exporting the questionnaire, you will be able to select which notes to include so that the questionnaire is not cluttered with non-relevant notes for the various readers.</p>" +
        "<p># You can omit the target (and just have 'NOTE: ...' or just 'NOTE ...') which means the specific note will be included in all exports.</p>" +
        "<p># Please note that this section will NOT be visible to survey respondents and will only be visible internally.</p>" +
        "<p>NOTE {{target}}: {{add note here}}</p>" +
        "<p>{{continue note here}}</p>" +
        "<p>{{use as many lines as you need}}</p>"
    ),
  section: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new section within the questionnaire.</p>" +
        "<p># Sections are used to organize a questionnaire and are like chapters of the questionnaire.</p>" +
        "<p># Sections themselves are NOT visible to survey respondents.</p>" +
        "<p>SECTION {{edit the name of the section here}}</p>"
    ),
  text: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add some text to the questionnaire that will be shown to the survey respondents.</p>" +
        "<p># Add spacing, new lines and text formatting as you would normally type it. Please also give the text a label</p>" +
        "<p># (e.g., Intro, Thank you, etc.) so that we can always refer to this (for example for translations).</p>" +
        "<p>TEXT {{label}}: {{add text here}}</p>" +
        "<p>{{continue text here}}</p>" +
        "<p>{{use as many lines as you need}}</p>"
    ),
  simple_list: () =>
    editor.commands.insertContent(
      "<p># Underlying is a basic template to add a new list within the questionnaire. The list will need a label (to refer to</p>" +
        "<p># this list from questions that use this list). The list elements should be entered just like you want them to be shown</p>" +
        "<p># to respondents. Note that the list elements will also be used as labels in the database. If you want the database</p>" +
        "<p># labels to be different than how elements are shown to respondents or need list elements to be different with</p>" +
        "<p># questions, please use the more advanced templates for lists.</p>" +
        "<p>LIST {{add label here}}</p>" +
        "<p>{{list option one}}</p>" +
        "<p>{{list option two}}</p>" +
        "<p>{{add as many list options as you want}}</p>"
    ),
  list_with_various_representations: () =>
    editor.commands.insertContent(
      "<p># Please do check out the instructions with the template for a 'Simple list' before moving to this template.</p>" +
        "<p># Underlying template shows how to create a list with multiple textual representations (with the list options applying</p>" +
        "<p># different text with different questions).</p>" +
        "<p># This template includes a header line identifying the various ways the list options can be represented to respondents.</p>" +
        "<p># Underlying template assumes three different textual representations for the list options (a default one and two</p>" +
        "<p># with identifiers.</p>" +
        "<p># The list options provides the various textual representations in columns split by the character '|'.</p>" +
        "<p># Please add as many list options as you like. Note that you are able to omit textual representations which will have the system</p>" +
        "<p># use the default representation. For example, if you have a list of media with two ways of representing the media you could</p>" +
        "<p># have the following:</p>" +
        "<p># LIST Media</p>" +
        "<p># HEADER full | abbreviated</p>" +
        "<p># Television | TV</p>" +
        "<p># Search (e.g., Google, Bing) | Search</p>" +
        "<p># Radio</p>" +
        "<p># As there is no abbreviated version for the option 'Radio' this will fallback to the use of the default 'Radio'.</p>" +
        "<p>LIST {{add label here}}</p>" +
        "<p>HEADER  {{repr1}} | {{repr2}} | {{repr3}}</p>" +
        "<p>{{add default text for option 1 here}} | {{add option 1 as identified in the header line here}} | {{third textual representation}}</p>" +
        "<p>{{option 2 - default}} | {{option 2}} | {{option 2}}</p>"
    ),
  exception_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template is to add a specific list to the questionnaire, a list with exceptions. Exceptions will be used as</p>" +
        "<p># additions to other lists. Examples of exceptions may include: 'None of the above', 'Prefer not to say', 'Other', etc.</p>" +
        "<p># For each list option, create a new line. Create as many exception list options as required and, if appropriate, remove</p>" +
        "<p># already provided exception list options.</p>" +
        "<p>LIST EXCEPTIONS</p>" +
        "<p>None of the above</p>" +
        "<p>Other</p>" +
        "<p>Prefer not to say</p>" +
        "<p>{{add exception list option}}</p>" +
        "<p>{{add exception list option}}</p>"
    ),
  fill_in_number: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will fill in a number response.</p>" +
        "<p># Please note, the question may have multiple lines (often the second line will include an instruction).</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>FILL IN NUMBER</p>"
    ),
  fill_in_text: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will fill in an open-ended text response.</p>" +
        "<p># Please note, the question may have multiple lines (often the second line will include an instruction).</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>FILL IN TEXT</p>"
    ),
  single_select_basic: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response.</p>" +
        "<p># This template adds the choices directly with the question instead of using a predefined list. This should only</p>" +
        "<p># be used if the options are only used for this question and the choice options are not shared across multiple questions.</p>" +
        "<p># Please note, the question may have multiple lines (often the second line will include an instruction).</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>SELECT ONE FROM</p>" +
        "<p>{{choice option 1}}</p>" +
        "<p>{{choice option 2}}</p>" +
        "<p>{{add as many list options as needed}}</p>" +
        "<p># Add one or more options from the exceptions list at the end for the system to recognize them as being exceptions.</p>" +
        "<p>{{exceptions, e.g., Prefer not to say/Other}}</p>"
    ),
  single_select_user_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response.</p>" +
        "<p># This template assumes the use of a predefined list that defines the various choice options.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>SELECT ONE FROM {{type in label of previously defined list here}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># SELECT ONE FROM {{type in label of previously defined list here}} | {{exception option 1}} + {{exception option 2}}</p>"
    ),
  multiple_select_basic: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a multiple choice response.</p>" +
        "<p># This template adds the choices directly with the question instead of using a predefined list. This should only</p>" +
        "<p># be used if the options are only used for this question and the choice options are not shared across multiple questions.</p>" +
        "<p># Please note, the question may have multiple lines (often the second line will include an instruction).</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>SELECT MULTIPLE FROM</p>" +
        "<p>{{choice option 1}}</p>" +
        "<p>{{choice option 2}}</p>" +
        "<p>{{add as many list options as needed}}</p>" +
        "<p># Add one or more options from the exceptions list at the end for the system to recognize them as being exceptions.</p>" +
        "<p>{{exceptions, e.g., Prefer not to say/Other}}</p>"
    ),
  multiple_select_user_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a multiple choice response.</p>" +
        "<p># This template assumes the use of a predefined list that defines the various choice options.</p>" +
        "<p>Q {{label of question}}: {{question}}</p>" +
        "<p>SELECT MULTIPLE FROM {{label of list}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># SELECT ONE FROM {{type in label of previously defined list here}} | {{exception option 1}} + {{exception option 2}}</p>"
    ),
  multiple_select_extended_with_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response.</p>" +
        "<p># This template assumes the use of a predefined list that has various textual representations included.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>SELECT MULTIPLE FROM {{type in label of previously defined list here}} AS {{type identifier of textual representation here}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># SELECT MULTIPLE FROM {{type in label of previously defined list here}} | {{exception 1}} + {{exception 2}} AS {{identifier of textual representation}}</p>"
    ),
  grid_single_select_basic: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template defines the grid options as well as the choices directly with the question instead of using a predefined list.</p>" +
        "<p># This should only be used if the options are only used for this question and the choice options are not shared across</p>" +
        "<p># multiple questions.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>PER</p>" +
        "<p>{{grid option 1}}</p>" +
        "<p>{{grid option 2}}</p>" +
        "<p>{{add as many options as needed}}</p>" +
        "<p>SELECT ONE FROM</p>" +
        "<p>{{choice option 1}}</p>" +
        "<p>{{choice option 2}}</p>" +
        "<p>{{add as many choice options as needed}}</p>" +
        "<p># Add one or more options from the exceptions list at the end for the system to recognize them as being exceptions.</p>" +
        "<p>{{exceptions, eg, Prefer not to say/Other}}</p>"
    ),
  grid_single_select_user_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template assumes the use of a predefined list that defines both the grid as the various choice options.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>PER {{label of previously defined list}} SELECT ONE FROM {{label of previously defined list}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># PER {{label of previously defined list}} SELECT ONE FROM {{label of previously defined list}} | {{exception}} + {{exception}}</p>"
    ),
  grid_single_select_extended_with_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template assumes the use of a predefined list that has various textual representations included.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p># Note that you can remove the AS part for either the list used with PER or the list used with SELECT ONE FROM.</p>" +
        "<p>PER {{list label}} AS {{identifier textual representation}} SELECT ONE FROM {{list label}} AS {{identifier textual representation}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># PER {{list label}} AS {{identifier}} SELECT ONE FROM {{list label}} | {{exception 1}} + {{exception 2}} AS {{identifier}}</p>"
    ),
  grid_multiple_select_basic: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template defines the grid options as well as the choices directly with the question instead of using a predefined list.</p>" +
        "<p># This should only be used if the options are only used for this question and the choice options are not shared across</p>" +
        "<p># multiple questions.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>PER</p>" +
        "<p>{{grid option 1}}</p>" +
        "<p>{{grid option 2}}</p>" +
        "<p>{{add as many options as needed}}</p>" +
        "<p>SELECT MULTIPLE FROM</p>" +
        "<p>{{choice option 1}}</p>" +
        "<p>{{choice option 2}}</p>" +
        "<p>{{add as many choice options as needed}}</p>" +
        "<p># Add one or more options from the exceptions list at the end for the system to recognize them as being exceptions.</p>" +
        "<p>{{exceptions, eg, Prefer not to say/Other}}</p>"
    ),
  grid_multiple_select_user_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template assumes the use of a predefined list that defines both the grid as the various choice options.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p>PER {{label of previously defined list}} SELECT MULTIPLE FROM {{label of previously defined list}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># PER {{label of previously defined list}} SELECT MULTIPLE FROM {{label of previously defined list}} | {{exception}} + {{exception}}</p>"
    ),
  grid_multiple_select_extended_with_predefined_list: () =>
    editor.commands.insertContent(
      "<p># Underlying template will be used to add a new question where a respondent will choose a single choice response from a grid.</p>" +
        "<p># This template assumes the use of a predefined list that has various textual representations included.</p>" +
        "<p>Q {{question label}}: {{type survey question here}}</p>" +
        "<p>{{type instruction here, delete this line if not used}}</p>" +
        "<p># Note that you can remove the AS part for either the list used with PER or the list used with SELECT MULTIPLE FROM.</p>" +
        "<p>PER {{list label}} AS {{identifier textual representation}} SELECT MULTIPLE FROM {{list label}} AS {{identifier textual representation}}</p>" +
        "<p># Please remove above line and use underlying line if you need to add exceptions to the answer options.</p>" +
        "<p># PER {{list label}} AS {{identifier}} SELECT MULTIPLE FROM {{list label}} | {{exception 1}} + {{exception 2}} AS {{identifier}}</p>"
    ),
  hidden_question: () =>
    editor.commands.insertContent(
      "<p># Underlying template shows how to add a hidden question to the questionnaire. Note that the format is identical to</p>" +
        "<p># adding a new question, just changing the 'Q' character to 'HIDDEN'. Best is to use templates for adding new questions</p>" +
        "<p># changing 'Q' to 'HIDDEN' and omitting the question text. Note that you would never need to use the templates that</p>" +
        "<p># include 'textual representations'.</p>" +
        "<p># This template is taken from a single select question using a predefined list.</p>" +
        "<p>HIDDEN {{label of hidden question}}:</p>" +
        "<p>PER {{label of previously defined list}} SELECT ONE FROM {{label of previously defined list}}</p>"
    ),
  data: () =>
    editor.commands.insertContent(
      "<p># Underlying template shows how to add a data element to the questionnaire. A data element is (per respondent)</p>" +
        "<p># information that is not generated by a response to a question but could be things like duration of answering</p>" +
        "<p># questionnaire, date or other additional information added by the hosting company.</p>" +
        "<p># The format of data fields are identical to questions by just changing the 'Q' character to 'DATA'.</p>" +
        "<p># Best is to use templates for adding new questions changing 'Q' to 'DATA' and omitting the question text.</p>" +
        "<p># Note that you would never need to use the templates that include 'textual representations'.</p>" +
        "<p># This template is taken from a FILLIN NUMBER question.</p>" +
        "<p>DATA {{label of data field}}</p>" +
        "<p>FILL IN NUMBER</p>"
    ),
  hide_a_question: () =>
    editor.commands.insertContent(
      "<p># When a question (defined in the master) is not relevant in a certain variant you can 'hide' that question</p>" +
        "<p># in the variant by adding underlying template in the variant.</p>" +
        "<p>HIDE {{label of question to hide}}</p>"
    ),
  extend_a_list: () =>
    editor.commands.insertContent(
      "<p># You can add elements to a list that is defined in the master for a certain variant. An example would</p>" +
        "<p># be a brand list and adding brands only available in a certain market. With underlying template you are able</p>" +
        "<p># to add options to already defined lists.</p>" +
        "<p># Note that if the original list has textual representations you can simply add them here as well (just like you would</p>" +
        "<p># if these elements were available in the original list)</p>" +
        "<p>EXTEND {{label of list}}</p>" +
        "<p>{{option to add}}</p>" +
        "<p>{{option to add}} | {{you can add textual representations to this extended list}}</p>"
    ),
  trim_a_list: () =>
    editor.commands.insertContent(
      "<p># You are able to remove elements from a list that is defined in the master for a certain variant. An example</p>" +
        "<p># would be to remove a brand that is not available in a certain market.</p>" +
        "<p># Note that to remove a list option with multiple textual representations, one needs to provide the text of</p>" +
        "<p># the first column as provided in the original list.</p>" +
        "<p>TRIM {{label of list}}</p>" +
        "<p>{{list option to remove}}</p>" +
        "<p>{{list option to remove}}</p>"
    ),

  reposition_questions: () =>
    editor.commands.insertContent(
      "<p># When adding questions in a variant you may want to tell the system where to position these new questions. This</p>" +
        "<p># is done by positioning the variant after an existing question in the master.</p>" +
        "<p>INSERT AFTER {{label of question in master}}</p>"
    ),
})

export default actions

import useApiCall from "hooks/useApiCall"
import {
  datafileUpload,
  datafileGet,
  datafileDelete,
  datafileCombine,
} from "api/Repos/data-import"

export const useDataImport = () => {
  const [uploadDataImportFile, isUploadingDataimportFile] =
    useApiCall(datafileUpload)
  const [getDataFilesUploaded, isGettingDataFilesUploaded] =
    useApiCall(datafileGet)
  const [deleteDataImportFile, isDeletingDataimportFile] =
    useApiCall(datafileDelete)
  const [combineDataFiles, isCombiningDataFiles] = useApiCall(datafileCombine)

  return {
    uploadDataImportFile,
    isUploadingDataimportFile,
    getDataFilesUploaded,
    isGettingDataFilesUploaded,
    deleteDataImportFile,
    isDeletingDataimportFile,
    combineDataFiles,
    isCombiningDataFiles,
  }
}

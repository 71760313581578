import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import DataQButton from "components/UI/Buttons/DataQButton"
import CreateVariantModal from "./CreateVariant/CreateVariantModal.component"
import SelectVariantModal from "./SelectVariant/SelectVariantModal.component"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

/**
 * `VariantsMenuBar` is a functional component that renders a menu bar for managing questionnaire variants.
 * The menu bar includes buttons for toggling the preview mode, selecting a variant, creating a new variant, and a placeholder for translation functionality.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.togglePreview - Function to toggle the preview mode.
 * @param {Object} props.selectedVariant - The currently selected variant object.
 * @param {Function} props.setSelectedVariant - Function to set the selected variant.
 * @param {Array<Object>} props.variants - Array of variant objects.
 * @param {Function} props.setVariants - Function to set the array of variants.
 * @param {Function} props.handleDeleteVariant - Function to handle the deletion of a variant.
 * @param {Function} props.refresh - Function to refresh the project data.
 * @param {Function} props.insertVariantText - Function to insert variant text into the editor.
 * @param {Function} props.setPreviewVisible - Function to control the visibility of the preview mode.
 * @param {Function} props.saveQuestionnaire - Function to save the questionnaire.
 * @returns {JSX.Element} The rendered VariantsMenuBar component.
 */
const VariantsMenuBar = (props) => {
  const project = useRecoilValue(projectAtom)

  const {
    togglePreview,
    selectedVariant,
    setSelectedVariant,
    variants,
    setVariants,
    handleDeleteVariant,
    refresh,
    insertVariantText,
    setPreviewVisible,
    saveQuestionnaire,
  } = props

  return (
    <div className="flex flex-row border-gray-300 border justify-evenly p-2 rounded-t-md">
      <DataQButton type="primary" onClick={() => togglePreview()}>
        Preview
        <FontAwesomeIcon icon={faMagnifyingGlass} className="ml-2" />
      </DataQButton>
      <SelectVariantModal
        project={project}
        variants={variants}
        handleDeleteVariant={handleDeleteVariant}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        refresh={refresh}
        setPreviewVisible={setPreviewVisible}
      />
      <CreateVariantModal
        project={project}
        setVariants={setVariants}
        variants={variants}
        selectedVariant={selectedVariant}
        insertVariantText={insertVariantText}
        setSelectedVariant={setSelectedVariant}
        saveQuestionnaire={saveQuestionnaire}
        refresh={refresh}
      />
      <DataQButton isDisabled type="default">
        Translation
      </DataQButton>
    </div>
  )
}

export default VariantsMenuBar

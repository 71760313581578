import { Node } from "@tiptap/core"

/**
 * CustomParagraph node for TipTap editor.
 *
 * This node extends the default paragraph node to allow for custom HTML rendering and attributes.
 */
const CustomParagraph = Node.create({
  /**
   * The name of the node.
   * @type {string}
   */
  name: "paragraph",

  /**
   * The group this node belongs to.
   * @type {string}
   */
  group: "block",

  /**
   * The content model for this node.
   * @type {string}
   */
  content: "inline*",

  /**
   * Defines how the node is parsed from HTML.
   * @returns {Array<Object>} An array of objects representing HTML tags.
   */
  parseHTML() {
    return [{ tag: "p" }]
  },

  /**
   * Defines how the node is rendered as HTML.
   * @param {Object} options - Options for rendering HTML.
   * @param {Object} options.HTMLAttributes - HTML attributes for the node.
   * @returns {Array} An array representing the HTML tag and its attributes.
   */
  renderHTML({ HTMLAttributes }) {
    return ["p", HTMLAttributes, 0]
  },

  /**
   * Adds custom attributes to the node.
   * @returns {Object} An object defining the node's attributes.
   */
  addAttributes() {
    return {
      class: {
        default: null,
      },
    }
  },
})

export default CustomParagraph

import axios from "../request"

/**
 * Fetches a questionnaire by ID.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {Object} qnr - The questionnaire content.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @throws Will throw an error if the request fails.
 */
export async function getQuestionnaire(token, user, unit, role, id, owner) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(
      `/questionnaire-get?id=${id}&hint_owner=${owner}`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function lockQuestionnaire(token, user, unit, role, id, owner) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    const response = await axios.get(
      `/questionnaire-locking?id=${id}&hint_owner=${owner}&lock`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function unlockQuestionnaire(
  token,
  user,
  unit,
  role,
  id,
  owner,
  session
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    const response = await axios.get(
      `/questionnaire-locking?id=${id}&hint_owner=${owner}&session=${session}&unlock`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function persistQuestionnaireLock(
  token,
  user,
  unit,
  role,
  id,
  owner,
  session
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    const response = await axios.get(
      `/questionnaire-locking?id=${id}&hint_owner=${owner}&session=${session}&persist`,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Saves a questionnaire.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} qnr - The questionnaire content.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function saveQuestionnaire(
  token,
  user,
  unit,
  role,
  id,
  qnr,
  owner,
  session
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    // Encode qnr into binary using TextEncoder
    const encoder = new TextEncoder()
    const binaryQnr = encoder.encode(qnr)

    const response = await axios.post(
      `/questionnaire-save?id=${id}&hint_owner=${owner}&session=${session}`,
      binaryQnr,
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Checks a questionnaire is valid.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function checkQuestionnaire(token, user, unit, role, id) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }

    const response = await axios.post(
      `/questionnaire-check`,
      { id: id },
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

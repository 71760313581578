import { List } from "antd"

/**
 * TemplateList component displays a list of templates, allowing the user to select one for further actions.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.templates - An array of template objects.
 * @param {string} props.templates[].label - The label or identifier of the template.
 * @param {string} props.templates[].text - The display text of the template.
 * @param {Function} props.onItemClick - Callback function that is called when a template is clicked.
 * @returns {JSX.Element} The rendered TemplateList component.
 */
const TemplateList = ({ templates, onItemClick }) => {
  return (
    <div className="flex flex-col space-y-2">
      <List
        itemLayout="horizontal"
        dataSource={templates}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            className="cursor-pointer hover:bg-gray-200 rounded-md border mb-1 shadow-sm"
            onClick={() => onItemClick(item.label)}
          >
            <List.Item.Meta
              className="p-2 text-center font-semibold"
              title={item.text}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default TemplateList

//TODO REFACTOR WITH downloadJSON.js
export function downloadFile(response, filename) {
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  })
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Converts raw data into a format suitable for rendering in a table, bar chart, and pie charts.
 * This function processes headers, rows, and identifies subheaders if present.
 * Additionally, it formats data for various charting purposes.
 *
 * @param {Object} data - The raw data object containing rows and headers for conversion.
 * @param {Array} data.data - A 2D array where each sub-array represents a row of data, with headers in the first row and potential subheaders in the second row.
 * @returns {Object} An object containing:
 *  - headers: Processed headers with optional subheaders.
 *  - rows: Transformed rows with appropriate formatting (e.g., percentages).
 *  - hasSubHeaders: Boolean indicating if subheaders are present.
 *  - barChartData: An object containing keys, data, xAxisLabel, and statisticTypes for bar chart rendering.
 *  - pieChartData: An object containing categoryData, subCategoryData, and statisticTypes for pie chart rendering.
 */
export const convertDataToTable = (data) => {
  const transformedData = {
    headers: [],
    rows: [],
    hasSubHeaders: false,
    barChartData: {
      keys: [],
      data: [],
      xAxisLabel: "",
      statisticTypes: [],
    },
    pieChartData: {
      categoryData: [],
      subCategoryData: [],
      statisticTypes: [],
    },
  }

  if (!data || !data.data || data.data.length === 0) {
    return transformedData
  }

  const headers = data.data[0] || []
  const secondRow = data.data[1] || []

  // Check if subheaders are present
  const isSubHeaderPresent =
    secondRow[1] === "Statistic" &&
    secondRow.some(
      (cell, index) => index > 1 && cell !== null && typeof cell === "string"
    )
  transformedData.hasSubHeaders = isSubHeaderPresent

  const subHeaders = isSubHeaderPresent ? secondRow : []
  const rows = isSubHeaderPresent ? data.data.slice(2) : data.data.slice(1)

  const isPercentageRow = (statistic) =>
    typeof statistic === "string" &&
    (statistic.toLowerCase().includes("perc") ||
      statistic.toLowerCase().includes("percent") ||
      statistic.toLowerCase().includes("%"))

  const convertToPercentage = (value) => {
    const num = parseFloat(value)
    return isNaN(num) ? value : `${(num * 100).toFixed(2)}%`
  }

  // Process headers for the table
  if (isSubHeaderPresent) {
    let currentGroup = null
    let groupColumns = []
    headers.forEach((header, index) => {
      if (header !== null) {
        if (currentGroup) {
          transformedData.headers.push({
            header: currentGroup,
            subHeaders: [...groupColumns],
          })
        }
        currentGroup = header
        groupColumns = []
      }
      groupColumns.push(subHeaders[index] || "")
    })
    if (currentGroup) {
      transformedData.headers.push({
        header: currentGroup,
        subHeaders: [...groupColumns],
      })
    }
  } else {
    transformedData.headers = headers.map((header) => ({
      header,
      subHeaders: [],
    }))
  }

  // Process rows for the table
  transformedData.rows = rows.map((row) => {
    const isPercentage = isPercentageRow(row[1])
    return row[0] !== null
      ? [
          row[0],
          row[1],
          ...row
            .slice(2)
            .map((cell) => (isPercentage ? convertToPercentage(cell) : cell)),
        ]
      : [
          null,
          row[1],
          ...row
            .slice(2)
            .map((cell) => (isPercentage ? convertToPercentage(cell) : cell)),
        ]
  })

  // Process bar chart data
  const barChartData = {}
  const statisticTypes = new Set()
  let currentCategory = null

  rows.forEach((row) => {
    if (row[0] !== null) {
      currentCategory = row[0]
    }
    const statistic = row[1]
    statisticTypes.add(statistic)
    if (currentCategory) {
      if (!barChartData[currentCategory]) {
        barChartData[currentCategory] = { xValue: currentCategory }
      }
      const headerSlice = isSubHeaderPresent ? subHeaders : headers
      headerSlice.slice(2).forEach((header, headerIndex) => {
        if (!barChartData[currentCategory][header]) {
          barChartData[currentCategory][header] = {}
        }
        barChartData[currentCategory][header][statistic] =
          parseFloat(row[headerIndex + 2]) || 0
      })
    }
  })

  // Store the processed bar chart data
  transformedData.barChartData.data = Object.values(barChartData)
  transformedData.barChartData.keys = isSubHeaderPresent
    ? subHeaders.slice(2)
    : headers.slice(2)
  transformedData.barChartData.statisticTypes = Array.from(statisticTypes)
  transformedData.barChartData.xAxisLabel = headers[0] || ""

  // Process pie chart data
  const categoryPieData = {}
  const subCategoryPieData = {}

  transformedData.barChartData.statisticTypes.forEach((statistic) => {
    categoryPieData[statistic] = []
    subCategoryPieData[statistic] = []

    // Category distribution (e.g., Yes, No)
    transformedData.barChartData.data.forEach((category) => {
      if (
        category.xValue !== "Totals" &&
        category.Totals &&
        category.Totals[statistic] !== undefined
      ) {
        categoryPieData[statistic].push({
          id: category.xValue,
          label: category.xValue,
          value: category.Totals[statistic],
        })
      }
    })

    // Sub-category distribution
    const subCategories = transformedData.barChartData.keys.filter(
      (key) => key !== "Totals"
    )
    subCategories.forEach((subCategory) => {
      const total = transformedData.barChartData.data.reduce(
        (sum, category) => {
          if (
            category.xValue !== "Totals" &&
            category[subCategory] &&
            category[subCategory][statistic] !== undefined
          ) {
            return sum + category[subCategory][statistic]
          }
          return sum
        },
        0
      )
      subCategoryPieData[statistic].push({
        id: subCategory,
        label: subCategory,
        value: total,
      })
    })
  })

  transformedData.pieChartData.categoryData = Object.entries(
    categoryPieData
  ).map(([statistic, data]) => ({
    statistic,
    data: data,
  }))
  transformedData.pieChartData.subCategoryData = Object.entries(
    subCategoryPieData
  ).map(([statistic, data]) => ({
    statistic,
    data: data,
  }))
  transformedData.pieChartData.statisticTypes =
    transformedData.barChartData.statisticTypes

  return transformedData
}

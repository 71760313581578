import React, { useState, useRef } from "react"
import { ResponsivePie } from "@nivo/pie"
import { Select } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { toPng } from "html-to-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartPie } from "@fortawesome/free-solid-svg-icons"

const { Option } = Select

const customColorScheme = [
  "#36c3ed",
  "#4a90e2",
  "#50e3c2",
  "#f5a623",
  "#b8e986",
  "#bd10e0",
  "#9013fe",
  "#417505",
  "#7ed321",
  "#8b572a",
]

const CustomTooltip = ({ id, value, color }) => (
  <div className="bg-white p-2 rounded-lg">
    <strong style={{ color: "#333" }}>{id}</strong>
    <br />
    <span style={{ color }}>{value}</span>
  </div>
)

const PieChart = ({ data, statisticTypes, chartType, category, isChart }) => {
  const [selectedStatistic, setSelectedStatistic] = useState(statisticTypes[0])
  const chartRef = useRef(null)

  if (!data || data.length === 0) {
    return <div>No data available for the chart</div>
  }
  // Handle case where chart rendering is disabled
  if (!isChart) {
    return (
      <div>Unable to display this output as a chart. Please view as table.</div>
    )
  }

  let currentData =
    data.find((d) => d.statistic === selectedStatistic)?.data || []

  // Filter data for sub-category charts
  if (chartType === "pie-subcategory" && category) {
    currentData = currentData.filter((item) => item.category === category)
  }

  const handleStatisticChange = (value) => {
    setSelectedStatistic(value)
  }

  const formatValue = (value) => {
    if (value === undefined || value === null) return "N/A"
    if (
      selectedStatistic.toLowerCase().includes("perc") ||
      selectedStatistic.toLowerCase().includes("percent") ||
      selectedStatistic.toLowerCase().includes("%")
    ) {
      return `${(value * 100).toFixed(2)}%`
    }
    return value.toLocaleString()
  }

  const exportChart = () => {
    if (chartRef.current) {
      toPng(chartRef.current, { quality: 0.95 })
        .then((dataUrl) => {
          const link = document.createElement("a")
          link.download = `pie_chart_${
            category || "all"
          }_${selectedStatistic}.png`
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.error("Error exporting chart:", err)
        })
    }
  }

  return (
    <div>
      <div className="flex mt-2 space-x-2">
        <Select
          style={{ width: 100, height: 40 }}
          value={selectedStatistic}
          onChange={handleStatisticChange}
        >
          {statisticTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
        <DataQButton type="primary" onClick={exportChart}>
          Export <FontAwesomeIcon className="ml-2" icon={faChartPie} />
        </DataQButton>
      </div>
      <div style={{ height: "400px" }} ref={chartRef}>
        <ResponsivePie
          data={currentData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={({ id }) =>
            customColorScheme[
              currentData.findIndex((item) => item.id === id) %
                customColorScheme.length
            ]
          }
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          valueFormat={formatValue}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
          tooltip={({ datum }) => (
            <CustomTooltip
              id={datum.id}
              value={formatValue(datum.value)}
              color={datum.color}
            />
          )}
        />
      </div>
    </div>
  )
}

export default PieChart

import { useMemo } from "react"
import { Skeleton } from "antd"

/**
 * Component for rendering a skeleton row in a table.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The columns configuration for the table.
 * @returns {JSX.Element} The rendered TableRowSkeleton component.
 */
const TableRowSkeleton = ({ columns }) => (
  <div className="flex w-full my-1" data-testid="loading-skeleton">
    {columns.map((col, index) => (
      <div key={index} className="mx-auto">
        <Skeleton.Input
          size="small"
          style={{ height: "24px", width: "180px" }}
          active={true}
        />
      </div>
    ))}
  </div>
)

/**
 * Component for rendering loading skeletons based on the specified type.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The columns configuration for the table skeleton.
 * @param {string} props.type - The type of loading skeleton to render (e.g., "table").
 * @returns {JSX.Element|null} The rendered LoadingSkeleton component or null if the type is not defined.
 */
const LoadingSkeleton = ({ columns, type }) => {
  const rows = useMemo(() => Array(3).fill(0), [])

  if (type === "table") {
    return rows.map((_, index) => (
      <TableRowSkeleton key={index} columns={columns} />
    ))
  } else {
    // define other types of loading skeletons here
    return null
  }
}

export default LoadingSkeleton

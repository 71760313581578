// Function to trigger download of JSON file
export const downloadJSON = (content, filename) => {
  const jsonString = JSON.stringify(content, null, 2)
  const blob = new Blob([jsonString], { type: "application/json" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = `${filename}.json`
  link.click()
}

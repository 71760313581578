import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import { useBlocker } from "react-router-dom"
import { useEditor, EditorContent } from "@tiptap/react"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import { Slice, Fragment, Node } from "prosemirror-model"

import FontChangeExtension from "./Extensions/FontChangeExtension"
import CustomParagraph from "./Extensions/CustomParagraphExtension"
import QuestionnaireCheckDisplay from "./QuestionnaireCheckDisplay.component"
import QuestionnaireMenuBar from "components/Home/QuestionnaireEditor/Editor/MenuBar/QuestionnaireMenuBar.component"
import EditorFooter from "./EditorFooter.component"
import ConfirmationModal from "components/UI/ConfimationModal/ConfimationModal"

import { escapeHTML } from "utils/escapeHtml"
import { useQuestionnaire } from "hooks/useQuestionnaire"

/**
 * Editor component provides a rich text editor for questionnaires.
 * It integrates with various extensions and allows for content manipulation, saving, and validation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.questionnaire - The initial content of the questionnaire.
 * @param {Function} props.saveQuestionnaire - Function to save the questionnaire content.
 * @param {Array} props.variants - The variants data that will be passed to the export form.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.isLocked - Indicates if the questionnaire is locked.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @param {Object} ref - The ref object for the component to expose methods.
 * @returns {JSX.Element} The rendered Editor component.
 */
const Editor = forwardRef((props, ref) => {
  const {
    questionnaire,
    saveQuestionnaire,
    variants,
    inMaster,
    isLocked,
    getQuestionnaireNotes,
  } = props

  const { isCheckingQuestionnaire, checkQuestionnaireByID } = useQuestionnaire()
  const editorContainerRef = useRef(null)
  const lineNumbersRef = useRef(null)
  const [highlightingEnabled, setHighlightingEnabled] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [editorContent, setEditorContent] = useState(questionnaire)
  const [checkingQuestionnaireEnabled, setCheckingQuestionnaireEnabled] =
    useState(false)
  const [checkQuestionnaireErrors, setCheckQuestionnaireErrors] = useState([])
  const [checkQuestionnaireWarnings, setCheckQuestionnaireWarnings] = useState(
    []
  )
  const [contentChanged, setContentChanged] = useState(false)
  const [lineNumbersEnabled, setLineNumbersEnabled] = useState(false)

  const qnrEmpty = questionnaire === "<p></p>"

  /**
   * Parses clipboard text into document nodes and creates a `Slice` object for insertion into a document.
   *
   * @param {string} text - The clipboard text to be parsed. Each line of text will be processed as a separate block.
   * @param {Object} context - The editor's document context, typically containing schema and doc information.
   * @param {boolean} plain - A flag indicating if the text is plain or contains rich formatting (currently not used in function).
   *
   * @returns {Slice} - A `Slice` object that contains a fragment of nodes, each representing a paragraph.
   */
  const clipboardTextParser = (text, context, plain) => {
    const blocks = text.replace().split(/(?:\r\n?|\n)/)
    const nodes = []

    blocks.forEach((line) => {
      let nodeJson = { type: "paragraph" }
      if (line.length > 0) {
        nodeJson.content = [{ type: "text", text: line }]
      }
      let node = Node.fromJSON(context.doc.type.schema, nodeJson)
      nodes.push(node)
    })

    const fragment = Fragment.fromArray(nodes)
    return Slice.maxOpen(fragment)
  }

  /**
   * Initializes and returns the extensions for the editor.
   *
   * @function getExtensions
   * @returns {Array} The array of editor extensions.
   */
  const getExtensions = () => [
    Document,
    Text,
    CustomParagraph,
    ...(highlightingEnabled ? [FontChangeExtension] : []),
  ]

  const editor = useEditor(
    {
      extensions: getExtensions(),
      content: editorContent,
      editorProps: {
        clipboardTextParser: clipboardTextParser,
      },
    },
    [highlightingEnabled, lineNumbersEnabled]
  )

  useImperativeHandle(ref, () => ({
    /**
     * Inserts content at the end of the editor.
     *
     * @function insertContentAtEnd
     * @param {string} text - The text to insert.
     */
    insertContentAtEnd: (text) => {
      if (editor) {
        const endPosition = editor.state.doc.content.size
        editor
          .chain()
          .focus()
          .insertContentAt(
            endPosition,
            text
              .split("\n")
              .map((s) => "<p>" + escapeHTML(s) + "</p>")
              .join("")
          )
          .run()
      }
    },
    /**
     * Retrieves the current HTML content of the editor.
     *
     * @function getHTML
     * @returns {string} The HTML content of the editor.
     */
    getHTML: () => {
      return editor ? editor.getHTML() : ""
    },
  }))

  // Auto save the questionnaire every 30 seconds
  useEffect(() => {
    let saveInterval

    if (editor && !isLocked) {
      saveInterval = setInterval(() => {
        setIsSaving(true)
        setEditorContent(editor.getHTML())
        saveQuestionnaire().then(() => {
          setTimeout(() => {
            setIsSaving(false)
            setContentChanged(false)
          }, 1000)
        })
      }, 30000) // 30 seconds
    }

    return () => {
      if (saveInterval) {
        clearInterval(saveInterval)
      }
    }
  }, [editor, isLocked, saveQuestionnaire])

  // Event listener to check if content has changed
  useEffect(() => {
    if (editor) {
      const updateContentChanged = () => {
        setContentChanged(true)
      }

      editor.on("update", updateContentChanged)

      return () => {
        editor.off("update", updateContentChanged)
      }
    }
  }, [editor])

  useEffect(() => {
    if (!editor) return

    const linePositions = [] // Track Y-positions of lines

    /**
     * Updates the line numbers next to the editor content based on the editor's state.
     * It calculates and stores the Y-positions of the lines for later reference.
     *
     * @function updateLineNumbers
     */
    const updateLineNumbers = () => {
      const lines = editor.view.dom.querySelectorAll(".ProseMirror > *")
      const lineNumbersContainer = lineNumbersRef.current
      if (!lineNumbersContainer) return

      lineNumbersContainer.innerHTML = ""
      linePositions.length = 0 // Reset positions

      lines.forEach((line, index) => {
        const lineNumber = document.createElement("div")
        lineNumber.className = "line-number"
        lineNumber.textContent = index + 1
        lineNumber.style.height = `${line.offsetHeight}px`
        lineNumbersContainer.appendChild(lineNumber)

        // Store the position of each line (top offset)
        linePositions.push(line.offsetTop)
      })
    }

    updateLineNumbers()
    editor.on("update", updateLineNumbers)

    const observer = new ResizeObserver(updateLineNumbers)
    observer.observe(editor.view.dom)

    return () => {
      observer.disconnect()
    }
  }, [editor])

  /**
   * Toggles the highlighting feature in the editor.
   *
   * @function toggleHighlighting
   */
  const toggleHighlighting = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setHighlightingEnabled(!highlightingEnabled)
  }

  /**
   * Toggles the line numbers visibility in the editor.
   *
   * @function toggleLineNumbers
   */
  const toggleLineNumbers = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setLineNumbersEnabled(!lineNumbersEnabled)
  }

  /**
   * Checks the questionnaire for errors and warnings by its ID.
   *
   * @async
   * @function checkQuestionnaire
   * @param {string} id - The ID of the questionnaire to check.
   */
  const checkQuestionnaire = async (id) => {
    try {
      manualSaveQuestionnaire() //Save the content before checking
      setCheckingQuestionnaireEnabled(true)
      const response = await checkQuestionnaireByID(id)
      setCheckQuestionnaireErrors(response.result.notifications.errors)
      setCheckQuestionnaireWarnings(response.result.notifications.unfinished)
    } catch (error) {
      console.error("Error checking questionnaire:", error.message)
    }
  }

  /**
   * Manually saves the questionnaire content.
   *
   * @function manualSaveQuestionnaire
   */
  const manualSaveQuestionnaire = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setIsSaving(true)
    saveQuestionnaire().then(() => {
      setTimeout(() => {
        setIsSaving(false)
        setContentChanged(false)
      }, 1000)
    })
  }

  /**
   * Scrolls the editor to a specific line number.
   *
   * @function scrollToLine
   * @param {number} lineNumber - The line number to scroll to.
   */
  const scrollToLine = (lineNumber) => {
    const linePositions = editor.view.dom.querySelectorAll(".ProseMirror > *")
    if (lineNumber < 1 || lineNumber > linePositions.length) return // Prevent out-of-range

    const lineY = linePositions[lineNumber - 1].offsetTop
    editorContainerRef.current.scrollTo({
      top: lineY,
      behavior: "smooth", // Smooth scroll for better UX
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      contentChanged !== false &&
      currentLocation.pathname !== nextLocation.pathname
  )

  /**
   * Handles the click event on an error to scroll to the corresponding line in the editor.
   *
   * @function handleErrorClick
   * @param {number} lineNumber - The line number associated with the error.
   */
  const handleErrorClick = (lineNumber) => {
    scrollToLine(lineNumber)
  }

  return (
    <div className="flex flex-col h-full">
      <ConfirmationModal
        title="Unsaved Changes"
        isVisible={blocker.state === "blocked"}
        message="You have unsaved changes, are you sure you want to leave?"
        cancel={blocker.reset}
        confirm={blocker.proceed}
        confirmButtonText="Leave"
        confirmButtonType="danger"
      />
      <QuestionnaireMenuBar
        inMaster={inMaster}
        qnrEmpty={qnrEmpty}
        variants={variants}
        editor={editor}
        highlightingEnabled={highlightingEnabled}
        toggleHighlighting={toggleHighlighting}
        checkQuestionnaire={checkQuestionnaire}
        getQuestionnaireNotes={getQuestionnaireNotes}
        toggleLineNumbers={toggleLineNumbers}
        lineNumbersEnabled={lineNumbersEnabled}
      />
      <div className="flex flex-col flex-grow overflow-hidden">
        <div
          ref={editorContainerRef}
          className="flex relative flex-grow overflow-y-auto w-full"
        >
          {lineNumbersEnabled && (
            <div
              ref={lineNumbersRef}
              className="line-numbers-questionnaire border-gray-300"
            ></div>
          )}
          <div className={lineNumbersEnabled ? "ml-10 w-full" : "w-full"}>
            <EditorContent className="h-full w-full" editor={editor} />
          </div>
        </div>

        {checkingQuestionnaireEnabled && (
          <QuestionnaireCheckDisplay
            setCheckingQuestionnaireEnabled={setCheckingQuestionnaireEnabled}
            checkQuestionnaireErrors={checkQuestionnaireErrors}
            checkQuestionnaireWarnings={checkQuestionnaireWarnings}
            checkQuestionnaire={checkQuestionnaire}
            isCheckingQuestionnaire={isCheckingQuestionnaire}
            handleErrorClick={handleErrorClick}
          />
        )}
      </div>
      <EditorFooter
        isLocked={isLocked}
        saveQuestionnaire={manualSaveQuestionnaire}
        isSaving={isSaving}
        contentChanged={contentChanged}
      />
    </div>
  )
})
export default Editor

import { Navigate, Outlet } from "react-router-dom"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import { Loader } from "components/UI/Loader/Loader"
/**
 * Renders the protected routes of the application based on authentication status.
 * If the user is authenticated, renders the child routes; otherwise, redirects to the home page.
 *
 * @returns {JSX.Element} The protected routes component.
 *
 *
 */
const ProtectedRoutes = () => {
  const { isLoading, isAuthenticated } = useKindeAuth()

  if (isLoading) {
    return (
      <div className="absolute left-2/4 top-2/4">
        <Loader fontSize={48} color="#36C3ED" />
      </div>
    )
  }

  return !isLoading && isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  )
}

export default ProtectedRoutes

import { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, Tree } from "antd"
import { faFolder, faRefresh } from "@fortawesome/free-solid-svg-icons"
import { DownOutlined } from "@ant-design/icons"
import { Loader } from "components/UI/Loader/Loader"

/**
 * Transforms the codebook data into a tree format for rendering.
 *
 * @param {Array} codebook - The codebook array containing data entries.
 * @returns {Array} - Returns the transformed codebook data in a tree format.
 */
const transformCodebookToTreeData = (codebook) => {
  return codebook.map((entry, index) => {
    const { label, type } = entry
    return {
      title: label,
      key: `${index}`,
      icon: <FontAwesomeIcon icon={faFolder} color="#36c3ed" />,
      isLeaf: type !== "TABLE", // Set isLeaf to true for non-TABLE types
    }
  })
}

/**
 * DataTree component displays a hierarchical view of the codebook data.
 * It supports lazy loading of child nodes and allows for dynamic tree height adjustments.
 *
 * @component
 * @param {Object} props - The props object for the component.
 * @param {Object} props.editor - The Tiptap editor instance.
 * @param {Array} props.codebook - The codebook data array.
 * @param {Function} props.regenerateCodebook - The function to regenerate the codebook.
 * @param {Boolean} props.isCodebookLoading - Boolean to indicate if the codebook is being regenerated.
 * @returns {JSX.Element} The DataTree component.
 */
const DataTree = ({
  editor,
  codebook,
  regenerateCodebook,
  isCodebookLoading,
}) => {
  const [treeData, setTreeData] = useState([])
  const [treeHeight, setTreeHeight] = useState(0)
  const treeContainerRef = useRef(null)

  useEffect(() => {
    if (codebook) {
      setTreeData(transformCodebookToTreeData(codebook))
    }
  }, [codebook])

  useEffect(() => {
    const updateTreeHeight = () => {
      if (treeContainerRef.current) {
        const containerHeight = treeContainerRef.current.clientHeight
        setTreeHeight(containerHeight)
      }
    }

    updateTreeHeight()
    window.addEventListener("resize", updateTreeHeight)

    return () => window.removeEventListener("resize", updateTreeHeight)
  }, [])

  /**
   * Function to lazily load child nodes when expanding tree nodes.
   *
   * @param {Object} node - The current node being expanded.
   * @returns {Promise<void>} A promise that resolves when the child nodes are loaded.
   */
  const loadData = (node) => {
    const { key } = node

    return new Promise((resolve) => {
      const parentKey = parseInt(key, 10)
      const parentNode = codebook[parentKey]

      if (parentNode.type === "TABLE" && Array.isArray(parentNode.value)) {
        const children = parentNode.value.map((subEntry, subIndex) => ({
          title: subEntry.label,
          key: `${key}-${subIndex}`,
          icon: <FontAwesomeIcon icon={faFolder} color="#36c3ed" />,
          isLeaf: true,
        }))

        setTreeData((prevData) => {
          const newData = [...prevData]
          const nodeToUpdate = newData.find((item) => item.key === key)
          if (nodeToUpdate) {
            nodeToUpdate.children = children
          }
          return newData
        })
      }
      resolve()
    })
  }

  /**
   * Handles inserting selected node information into the editor.
   *
   * @param {Object} selectedKeys - The keys of the selected nodes.
   * @param {Object} info - Information about the selected node.
   */
  const onSelect = (selectedKeys, info) => {
    const { node } = info
    const { key, title } = node

    if (!key.includes("-")) {
      editor.commands.insertContent(`<<${title}>> `)
    } else {
      const parentKey = key.split("-")[0]
      const parentNode = treeData.find((item) => item.key === parentKey)
      if (parentNode) {
        editor.commands.insertContent(`<<${parentNode.title}//${title}>> `)
      }
    }
  }

  if (isCodebookLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={48} color="#36C3ED" />
        <span>Regenerating Data Tree...</span>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex w-full mb-2 shadow-sm p-2">
        <Tooltip title="Regenerate Data Tree">
          <FontAwesomeIcon
            icon={faRefresh}
            onClick={regenerateCodebook}
            className="ml-auto cursor-pointer w-4 h-4"
            color="#36C3ED"
          />
        </Tooltip>
      </div>
      <div ref={treeContainerRef} className="flex-grow overflow-hidden">
        {treeHeight > 0 && (
          <Tree
            showIcon
            virtual
            height={treeHeight}
            defaultSelectedKeys={["0-0-0"]}
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            loadData={loadData}
            onSelect={onSelect}
          />
        )}
      </div>
    </div>
  )
}

export default DataTree

import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilValue, useRecoilState } from "recoil"
import { Helmet, HelmetProvider } from "react-helmet-async"

import ProjectDetailsContainer from "containers/Home/ProjectDetails.container"

import { Loader } from "components/UI/Loader/Loader"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

import { useProjects } from "hooks/useProjects"
import { userAtom } from "atoms/userAtom"
import { projectAtom } from "atoms/projectAtom"
/**
 * ProjectDetails route component - Fetches and displays details of a project.
 *
 * Utilizes the `useProjectDetails` hook to fetch project data based on the project ID
 * obtained from the URL parameters. Displays a loader while the data is being fetched
 * and displays project details once the data is successfully retrieved.
 *
 * @component
 * @returns {JSX.Element} The ProjectDetails component.
 */
const ProjectDetails = () => {
  const { getProjectDetails, isLoadingProjectDetails } = useProjects()
  const [project, setProject] = useRecoilState(projectAtom)
  const navigate = useNavigate()
  const user = useRecoilValue(userAtom)

  // Extracts the project ID from the URL parameters
  let { id } = useParams()

  useEffect(() => {
    // Redirect to home if user state is lost
    if (!user || !user.username || !user.unit) {
      navigate("/home")
    } else {
      // If user state is valid, proceed with fetching project details
      fetchProjectDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  /**
   * Fetches the project details and updates the state.
   *
   * @async
   * @function fetchProjectDetails
   * @returns {Promise<void>} A promise that resolves when the project details are fetched and state is updated.
   */
  const fetchProjectDetails = async () => {
    try {
      const res = await getProjectDetails(id)
      if (!res) {
        navigate("/home")
        ErrorNotification(
          "Project Not Found",
          "The project you are looking for does not exist."
        )
      } else {
        setProject({ id: res.result.id, ...res.result.metadata })
      }
    } catch (error) {
      console.log("Error Fetching Data:", error.message)
    }
  }

  if (isLoadingProjectDetails) {
    return (
      <div className="absolute left-2/4 top-2/4">
        <Loader fontSize={48} color="#36C3ED" />
      </div>
    )
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{project.Name}</title>
        </Helmet>
      </HelmetProvider>
      <ProjectDetailsContainer refreshProject={fetchProjectDetails} />
    </>
  )
}

export default ProjectDetails

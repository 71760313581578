import { useEffect, useRef } from "react"
import { EditorContent } from "@tiptap/react"
import Output from "./Output.component"
import Split from "react-split"

/**
 * Console component for writing and running scripts.
 *
 * This component provides a split view with an editor for writing scripts
 * and an output area for displaying results. It includes line numbers for the editor
 * and allows resizing of the editor and output areas.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.editor - The Tiptap editor instance.
 * @param {Object} props.result - The result of the script execution.
 * @param {boolean} props.scriptInProgress - Indicates whether a script is currently running.
 * @returns {JSX.Element} The rendered Console component.
 */
const Console = ({ editor, result, scriptInProgress }) => {
  const editorWrapperRef = useRef(null)
  const lineNumbersRef = useRef(null)

  /**
   * Updates the line numbers in the editor.
   * This effect runs when the editor instance changes or updates.
   */
  useEffect(() => {
    if (!editor) return

    /**
     * Updates the line numbers based on the current editor content.
     */
    const updateLineNumbers = () => {
      const lines = editor.view.dom.querySelectorAll(".ProseMirror > *")
      const lineNumbersContainer = lineNumbersRef.current
      if (!lineNumbersContainer) return
      lineNumbersContainer.innerHTML = ""
      lines.forEach((line, index) => {
        const lineNumber = document.createElement("div")
        lineNumber.className = "line-number"
        lineNumber.textContent = index + 1
        lineNumber.style.height = `${line.offsetHeight}px`
        lineNumbersContainer.appendChild(lineNumber)
      })
    }

    updateLineNumbers()
    editor.on("update", updateLineNumbers)

    // Set up ResizeObserver to update line numbers on editor resize
    const observer = new ResizeObserver(updateLineNumbers)
    observer.observe(editor.view.dom)

    // Cleanup function
    return () => {
      observer.disconnect()
    }
  }, [editor])

  return (
    <Split
      direction="vertical"
      sizes={[50, 50]}
      minSize={100}
      expandToMin={false}
      gutterSize={10}
      gutterAlign="center"
      snapOffset={30}
      dragInterval={1}
      className="split-container h-full flex flex-col"
    >
      <div className="flex flex-col min-h-0 relative">
        <div
          ref={editorWrapperRef}
          className="flex relative overflow-auto flex-grow"
        >
          <div
            ref={lineNumbersRef}
            className="line-numbers border-gray-300 absolute left-0 top-0"
          >
            {/* Line numbers will be inserted here */}
          </div>
          <EditorContent editor={editor} className="editor-content w-full" />
        </div>
      </div>
      <Output result={result} scriptInProgress={scriptInProgress} />
    </Split>
  )
}

export default Console

import * as XLSX from "xlsx"

export const exportToExcel = (data, filename) => {
  const worksheet = XLSX.utils.aoa_to_sheet(data) // If data is an array of arrays (AOA)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

  const excelData = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  })

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  const blob = new Blob([s2ab(excelData)], { type: "application/octet-stream" })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = `${filename}.xlsx`
  a.click()
}

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import ProjectDetailsTabs from "./ProjectDetailsTabs.container"
import ProjectBreadcrumb from "components/Home/ProjectDetails/ProjectDetailsHeader/ProjectBreadcrumb.component"
import ProjectStatus from "components/Home/ProjectDetails/ProjectDetailsHeader/ProjectStatus.component"
import ProjectActions from "components/Home/ProjectDetails/ProjectDetailsHeader/ProjectActions.component"

/**
 * ProjectDetailsContainer component - Displays the project details along with navigation breadcrumbs.
 *
 * This component is responsible for rendering the overall layout of the project details page. It includes
 * navigation breadcrumbs at the top for easy navigation, the project's status, and the project's name in a
 * prominent position. The `ProjectDetailsTabs` component is used to display detailed sections of the project
 * information below the header.
 *
 * The layout is structured with a top bar containing the breadcrumbs and the project status, followed by the
 * project name. The `ProjectBreadcrumb` component is used to render the navigation breadcrumbs, and the
 * `ProjectStatus` component displays the current status of the project. The project name is displayed as a
 * large header, emphasizing the focus of the page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.refreshProject - Function to refresh the project details. Not directly used in this
 *                                          excerpt but  used in child components or further actions.
 * @returns {JSX.Element} The rendered ProjectDetailsContainer component, including breadcrumbs, status, and project name.
 */

//TODO Add refreshProject to selector recoil
const ProjectDetailsContainer = ({ refreshProject }) => {
  const project = useRecoilValue(projectAtom)

  return (
    <div id="project-details" className="flex flex-col p-5 h-full">
      <div className="flex flex-row mb-2">
        {/* Renders the project breadcrumbs */}

        <ProjectBreadcrumb />
        <div className="ml-auto">
          {/* Renders the project status */}
          <ProjectStatus />
        </div>
      </div>
      <div className="flex flex-row">
        {/* Renders the project name */}
        <div className="flex flex-row w-full">
          <div>
            <p className=" text-3xl font-semibold">{project.Name}</p>
          </div>
          <div className="ml-auto space-x-1">
            {/* Renders the project actions */}
            <ProjectActions />
          </div>
        </div>
      </div>
      <div className="mt-5 h-full overflow-y-hidden">
        {/* Renders the project details tabs */}
        <ProjectDetailsTabs refreshProject={refreshProject} />
      </div>
    </div>
  )
}

export default ProjectDetailsContainer

/**
 * An object mapping special characters to their HTML escape codes.
 * @type {Object<string, string>}
 * @property {string} ¢ - The cent symbol.
 * @property {string} £ - The pound symbol.
 * @property {string} ¥ - The yen symbol.
 * @property {string} € - The euro symbol.
 * @property {string} © - The copyright symbol.
 * @property {string} ® - The registered trademark symbol.
 * @property {string} < - The less-than symbol.
 * @property {string} > - The greater-than symbol.
 * @property {string} " - The double quotation mark.
 * @property {string} & - The ampersand.
 * @property {string} ' - The single quotation mark (represented as #39).
 */
var escapeChars = {
  "¢": "cent",
  "£": "pound",
  "¥": "yen",
  "€": "euro",
  "©": "copy",
  "®": "reg",
  "<": "lt",
  ">": "gt",
  '"': "quot",
  "&": "amp",
  "'": "#39",
}

/**
 * A string representing a character set for a regular expression.
 * This string is built from the keys of the `escapeChars` object.
 * @type {string}
 */
var regexString = "["
for (var key in escapeChars) {
  regexString += key
}
regexString += "]"

/**
 * A regular expression that matches any of the characters in `escapeChars`.
 * @type {RegExp}
 */
var regex = new RegExp(regexString, "g")

/**
 * Escapes special HTML characters in a given string by replacing them with their corresponding HTML entities.
 *
 * @param {string} str - The input string to escape.
 * @returns {string} The escaped string with special HTML characters replaced by their corresponding HTML entities.
 *
 * @example
 * escapeHTML('5 < 10 & 20 > 15');
 * // Returns '5 &lt; 10 &amp; 20 &gt; 15'
 */
export function escapeHTML(str) {
  return str.replace(regex, function (m) {
    return "&" + escapeChars[m] + ";"
  })
}

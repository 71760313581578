import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useProjects } from "hooks/useProjects"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBoxArchive, faTrash } from "@fortawesome/free-solid-svg-icons"

import DataQButton from "components/UI/Buttons/DataQButton"
import ConfirmationModal from "components/UI/ConfimationModal/ConfimationModal"
import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import ProjectMembersModal from "./ProjectMembers/ProjectMembersModal"

import handleSequenceError from "utils/handleSequenceError"

/**
 * Renders the actions available for a project.
 *
 * This component renders buttons for actions such as deleting the project,
 * archiving it, and managing members.
 *
 * @component
 * @returns {JSX.Element} The rendered ProjectActions component.
 */
const ProjectActions = () => {
  const { deleteUserProject, isDeletingProject } = useProjects()
  const navigate = useNavigate()
  const project = useRecoilValue(projectAtom)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  /**
   * Handles the project deletion process.
   *
   * This function is triggered when the delete button is clicked. It calls the `deleteUserProject` function
   * from the `useProjects` hook and handles success and error notifications accordingly.
   *
   * @async
   * @function deleteProject
   * @param {Event} e - The event object.
   * @returns {Promise<void>} A promise that resolves when the project is successfully deleted or rejects if an error occurs.
   */
  const deleteProject = async (e) => {
    e.preventDefault()
    try {
      const res = await deleteUserProject(project.id)

      if (handleSequenceError(res, "Error Deleting Project!")) {
        SuccessNotification(
          "Project Deleted Successfully!",
          "The project has been deleted successfully."
        )
        navigate("/home")
      }
    } catch (error) {
      ErrorNotification("Error Deleting Project!", error.message)
    }
  }

  return (
    <>
      <ConfirmationModal
        title={"Delete Project"}
        isVisible={isDeleteModalVisible}
        cancel={setIsDeleteModalVisible}
        confirm={deleteProject}
        confirmButtonText="Delete"
        confirmButtonType="danger"
        message="Are you sure you want to delete this project? This action cannot be undone."
      />
      {/* Button to delete the project */}
      <DataQButton
        onClick={() => setIsDeleteModalVisible(true)}
        type="primary"
        isLoading={isDeletingProject}
      >
        Delete Project
        <FontAwesomeIcon className="ml-2" icon={faTrash} />
      </DataQButton>
      {/* Button to archive the project */}
      <DataQButton type="primary" isDisabled>
        Archive <FontAwesomeIcon className="ml-2" icon={faBoxArchive} />
      </DataQButton>
      {/* Button to manage project members */}
      <ProjectMembersModal />
    </>
  )
}

export default ProjectActions

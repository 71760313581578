/**
 * Parses the questionnaire text to extract variants.
 *
 * This function processes the questionnaire text, extracting different variants defined
 * by `#DEFINE VARIANT` lines and their associated content. Each variant includes its name,
 * languages, and content.
 *
 * @param {string} text - The questionnaire text to parse.
 * @returns {Array} An array of variant objects. Each object contains the variant's name, languages, and content.
 * @returns {Object[]} return[].variants - The array of variant objects.
 * @returns {string} return[].variants[].name - The name of the variant.
 * @returns {string[]} return[].variants[].languages - The languages associated with the variant.
 * @returns {string} return[].variants[].content - The content of the variant.
 */
export const parseQuestionnaireVariant = (text) => {
  const lines = text.split("\n")
  const variants = []
  let currentVariant = null
  let currentContent = []

  lines.forEach((line) => {
    const defineVariantMatch = line.match(/^#DEFINE VARIANT (.+)/) // Capture everything after #DEFINE VARIANT
    const languageMatch = line.match(/^#LANGUAGE (.+)/)

    // Check if we encounter #DEFINE VARIANT
    if (defineVariantMatch) {
      if (currentVariant) {
        currentVariant.content = currentContent.join("\n").trim()
        variants.push(currentVariant)
      }
      currentVariant = {
        name: defineVariantMatch[1].trim(), // Trim any excess whitespace
        languages: [],
        content: "",
      }
      currentContent = [] // Reset the content, excluding the define line
    }
    // Check if we encounter #LANGUAGE
    else if (languageMatch && currentVariant) {
      currentVariant.languages = languageMatch[1]
        .split(",")
        .map((lang) => lang.trim())
      currentContent.push("") // Ensuring a blank line after language line
    }
    // Check if the line is not #Line 0 before adding it to the content
    else if (currentVariant && !line.startsWith("#LINE 0")) {
      currentContent.push(line)
    }
  })

  // Ensure to add the last variant if the loop ends
  if (currentVariant) {
    currentVariant.content = currentContent.join("\n").trim()
    variants.push(currentVariant)
  }

  return variants
}

/**
 * Updates the questionnaire with the variant content.
 *
 * @param {string} updatedQuestionnaire - The original questionnaire.
 * @param {string} selectedVariantName - The name of the selected variant.
 * @param {string} text - The variant content.
 * @returns {string} The updated questionnaire.
 */
export const updateQuestionnaireWithVariant = (
  updatedQuestionnaire,
  selectedVariantName,
  text,
  languages = []
) => {
  const defineLine = `#DEFINE VARIANT ${selectedVariantName}`
  const languageLine = languages.length
    ? `#LANGUAGE ${languages.join(", ")}`
    : ""

  // Ensure that the variant content is surrounded by proper newlines
  const variantContent =
    `\n${defineLine}\n${languageLine}\n#LINE 0\n${text.trim()}\n`.trim()

  // Use regular expression to find and replace the existing variant block
  const variantRegex = new RegExp(
    `(#DEFINE VARIANT ${selectedVariantName}\\s*[\\s\\S]*?)(?=#DEFINE VARIANT|$)`,
    "g"
  )

  if (variantRegex.test(updatedQuestionnaire)) {
    return updatedQuestionnaire.replace(variantRegex, `\n${variantContent}\n`)
  } else {
    // Append the new variant content to the questionnaire, ensuring proper newline separation
    return `${updatedQuestionnaire.trim()}\n\n${variantContent}\n`.trim()
  }
}

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"
/**
 * Renders the status of a project.
 *
 * This component displays the current status of a project along with
 * additional information such as awaiting data and reporting.
 *
 * @component
 * @returns {JSX.Element} The rendered ProjectStatus component.
 */
const ProjectStatus = () => {
  const project = useRecoilValue(projectAtom)

  return (
    <p>Current project status: {project.Status} | Awaiting data | Reporting</p>
  )
}
export default ProjectStatus

export const calculateFileUploadDuration = (fileSize) => {
  // Convert fileSize from bytes to megabytes
  const sizeInMB = fileSize / (1024 * 1024)

  // Compression factor (adjust this based on your compression ratio)
  const compressionFactor = 0.1 // Assuming compression reduces size by 80%

  // Estimate duration (e.g., 1 second per MB before compression, but adjusted by compression factor)
  const adjustedSize = sizeInMB * compressionFactor

  return Math.max(2000, adjustedSize * 1000) // Minimum duration of 3 seconds
}

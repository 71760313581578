import { useEffect, useState } from "react"
import { Modal, Typography, List, Checkbox } from "antd"
import { useRecoilValue } from "recoil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faUsers } from "@fortawesome/free-solid-svg-icons"

import { useProjects } from "hooks/useProjects"
import { useUnits } from "hooks/useUnits"

import DataQButton from "components/UI/Buttons/DataQButton"
import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"

import { projectAtom } from "atoms/projectAtom"

import handleSequenceError from "utils/handleSequenceError"
/**
 * Modal component for managing project members.
 *
 * This component displays a list of users and allows adding/removing them as project members.
 *
 * @returns {JSX.Element} The rendered project members modal component.
 */
const ProjectMembersModal = () => {
  const project = useRecoilValue(projectAtom)
  const { getProjectMembers, addProjectMember, removeProjectMember } =
    useProjects()

  const { getUnitUsers, isLoadingUnitUsers } = useUnits()
  const { Title } = Typography
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [projectMembers, setProjectMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])
  const [userData, setUserData] = useState([])

  useEffect(() => {
    if (project.id) {
      getUserData()
      getMembers(project.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id])

  /**
   * Fetches the users in unit
   * @async
   * @function getUserData
   * @returns {Promise<void>} A promise that resolves when the project members are fetched or rejects if an error occurs.
   */
  const getUserData = async () => {
    try {
      const res = await getUnitUsers()
      let userArray = res.split("\n")

      // Ensure project owner is the first in the list
      userArray = [
        project.Created_by,
        ...userArray.filter((user) => user !== project.Created_by),
      ]

      setUserData(userArray || [])
    } catch (error) {
      console.log("Error Fetching Data:", error.message)
    }
  }

  /**
   * Fetches the members of the project.
   * @async
   * @function getMembers
   * @returns {Promise<void>} A promise that resolves when the project members are fetched or rejects if an error occurs.
   */
  const getMembers = async () => {
    try {
      const res = await getProjectMembers(project.id)
      let memberArray = res.split("\n")
      setProjectMembers(memberArray || [])
    } catch (error) {
      console.log("Error Fetching Data:", error.message)
    }
  }

  /**
   * Adds selected members to the project.
   * @async
   * @function addMember
   * @returns {Promise<void>} A promise that resolves when all selected members are added or rejects if an error occurs.
   */
  const addMember = async () => {
    try {
      for (const member of selectedMembers) {
        if (projectMembers.includes(member)) {
          // Throw an error notification if the member is already in the project
          ErrorNotification(
            "Member Already Exists!",
            `Member ${member} is already a part of the project.`
          )
          continue
        }

        const res = await addProjectMember(project.id, member)
        if (handleSequenceError(res, "Error Adding Member!")) {
          SuccessNotification(
            "Member Added Successfully!",
            `Member ${member} has been added successfully.`
          )
        }
      }
      // Refresh the list of members after adding all selected members
      getMembers()
    } catch (error) {
      console.log("Error Adding Members:", error.message)
    }
  }

  /**
   * Removes a member from the project.
   * @async
   * @function removeMember
   * @param {string} member_name - The name of the member to remove.
   * @returns {Promise<void>} A promise that resolves when the member is removed or rejects if an error occurs.
   */
  const removeMember = async (member_name) => {
    try {
      const res = await removeProjectMember(project.id, member_name)
      if (handleSequenceError(res, "Error Removing Member!")) {
        SuccessNotification(
          "Member Removed Successfully!",
          `Member ${member_name} has been removed successfully.`
        )
        getMembers()
      }
    } catch (error) {
      console.log("Error Removing Member:", error.message)
    }
  }

  /**
   * Shows the modal.
   * @function showModal
   */
  const showModal = () => {
    setIsModalVisible(true)
  }

  /**
   * Hides the modal.
   * @function cancel
   */
  const cancel = () => {
    setIsModalVisible(false)
  }

  /**
   * Handles the selection of a project member.
   *
   * @function handleMemberSelect
   * @param {String} selectedMember - The selected member string.
   * @returns {Function} The event handler function.
   */
  const handleMemberSelect = (selectedMember) => () => {
    // Prevent the project creator from being added to the selected members
    if (selectedMember === project.Created_by) {
      return
    }

    setSelectedMembers((prevSelected) =>
      prevSelected.includes(selectedMember)
        ? prevSelected.filter((member) => member !== selectedMember)
        : [...prevSelected, selectedMember]
    )
  }

  if (!userData || projectMembers.length === 0) return null
  return (
    <>
      <DataQButton onClick={showModal} type="primary" classname="text-black">
        Members <FontAwesomeIcon className="ml-2" icon={faUsers} />
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <Title style={{ marginTop: "0.5em" }} level={4}>
          Project Members
        </Title>
        <List
          loading={isLoadingUnitUsers}
          itemLayout="horizontal"
          dataSource={userData}
          renderItem={(member) => {
            const isMember = projectMembers.includes(member)
            return (
              <List.Item
                onClick={handleMemberSelect(member)}
                className="cursor-pointer hover:bg-gray-200 rounded-2xl"
                actions={[
                  <div>
                    {isMember && project.Created_by !== member && (
                      <FontAwesomeIcon
                        onClick={() => removeMember(member)}
                        icon={faTrash}
                        className="mr-2"
                      />
                    )}
                    <Checkbox
                      className="mr-2"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleMemberSelect(member)()
                      }}
                      checked={isMember || selectedMembers.includes(member)}
                    />
                  </div>,
                ]}
              >
                <List.Item.Meta className="p-2 ml-2" title={member} />
              </List.Item>
            )
          }}
        />
        <div className="flex flex-row mt-5">
          <DataQButton
            onClick={addMember}
            className="ml-auto mr-2"
            type="primary"
          >
            Add Member
          </DataQButton>
          <DataQButton type="secondary" onClick={cancel}>
            Cancel
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default ProjectMembersModal

import { Modal, Typography } from "antd"
import ExportToDecipherOptionsForm from "./ExportToDecipherOptionsForm.component"

/**
 * ExportToDecipherModal component renders a modal for exporting a project to Decipher XML format.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @param {Object} props.project - The project data that will be exported.
 * @param {boolean} props.isExportDecipherModalVisible - Determines if the modal is visible.
 * @param {Function} props.setExportDecipherModalVisible - Function to toggle the visibility of the modal.
 * @param {Array} props.variants - The variants data that will be passed to the export form.
 * @returns {JSX.Element} The rendered ExportToDecipherModal component.
 */
const ExportToDecipherModal = (props) => {
  const {
    getQuestionnaireNotes,
    project,
    isExportDecipherModalVisible,
    setExportDecipherModalVisible,
    variants,
  } = props
  const { Title } = Typography

  return (
    <>
      <Modal
        open={isExportDecipherModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <Title style={{ marginBottom: "0.5em" }} level={4}>
          Export To Decipher XML
        </Title>
        <ExportToDecipherOptionsForm
          getQuestionnaireNotes={getQuestionnaireNotes}
          project={project}
          variants={variants}
          setExportDecipherModalVisible={setExportDecipherModalVisible}
        />
      </Modal>
    </>
  )
}

export default ExportToDecipherModal

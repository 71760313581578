import useApiCall from "hooks/useApiCall"
import {
  convertToDecipherXML,
  convertToWord,
  downloadHTML,
} from "api/Engine/convert"
import {
  downloadDatamap,
  InitialiseDataReporting,
  RunQuery,
} from "api/Engine/data-reporting"
import { exportToJSON, exportToSPS, exportToSQL } from "api/Engine/export"

/**
 * Custom hook `useEngine` for handling various engine-related operations.
 *
 * This hook encapsulates the logic for converting documents, downloading files, checking data, initializing reporting, and running queries.
 * It leverages the `useApiCall` hook for managing the API calls and loading states.
 *
 * @returns {Object} - An object containing functions for various engine operations and their loading states.
 *
 * @example
 * const {
 *   convertToXMLDecipher,
 *   isConvertingToXMLDecipher,
 *   convertToWordPreview,
 *   isConvertingToWordPreview,
 *   downloadDatamapCSV,
 *   isDownloadingDatamapCSV,
 *   checkDataFileExists,
 *   isCheckingDataFileExists,
 *   initialiseData,
 *   isInitialisingData,
 *   RunEngineQuery,
 *   isRunningEngineQuery,
 *   exportToHTML,
 *   isExportingHTML,
 * } = useEngine();
 */
export const useEngine = () => {
  const [convertToXMLDecipher, isConvertingToXMLDecipher] =
    useApiCall(convertToDecipherXML)
  const [convertToWordPreview, isConvertingToWordPreview] =
    useApiCall(convertToWord)
  const [downloadDatamapCSV, isDownloadingDatamapCSV] =
    useApiCall(downloadDatamap)
  const [initialiseData, isInitialisingData] = useApiCall(
    InitialiseDataReporting
  )
  const [RunEngineQuery, isRunningEngineQuery] = useApiCall(RunQuery)
  const [exportToHTML, isExportingHTML] = useApiCall(downloadHTML)
  const [exportJSON, isExportingJSON] = useApiCall(exportToJSON)
  const [exportSQL, isExportingSQL] = useApiCall(exportToSQL)
  const [exportSPS, isExportingSPS] = useApiCall(exportToSPS)

  return {
    /**
     * Converts a project to Decipher XML format.
     *
     * @function convertToXMLDecipher
     * @param {string} id - The project ID.
     * @param {Object} options - The options for the conversion.
     * @returns {Promise<Object>} - The response from the API after converting to Decipher XML.
     * @throws Will throw an error if the conversion fails.
     */
    convertToXMLDecipher,

    /**
     * Indicates whether the conversion to Decipher XML is in progress.
     *
     * @type {boolean}
     */
    isConvertingToXMLDecipher,

    /**
     * Converts a project to a Word preview format.
     *
     * @function convertToWordPreview
     * @param {string} id - The project ID.
     * @param {Object} options - The options for the conversion.
     * @returns {Promise<Object>} - The response from the API after converting to Word preview.
     * @throws Will throw an error if the conversion fails.
     */
    convertToWordPreview,

    /**
     * Indicates whether the conversion to Word preview is in progress.
     *
     * @type {boolean}
     */
    isConvertingToWordPreview,

    /**
     * Downloads the Datamap as a CSV file.
     *
     * @function downloadDatamapCSV
     * @param {string} id - The project ID.
     * @param {string} filename - The name of the file to download.
     * @returns {Promise<Blob>} - The CSV file as a Blob.
     * @throws Will throw an error if the download fails.
     */
    downloadDatamapCSV,

    /**
     * Indicates whether the Datamap CSV download is in progress.
     *
     * @type {boolean}
     */
    isDownloadingDatamapCSV,

    /**
     * Initializes data reporting for a project.
     *
     * @function initialiseData
     * @param {string} id - The project ID.
     * @returns {Promise<Object>} - The response from the API after initializing data reporting.
     * @throws Will throw an error if the initialization fails.
     */
    initialiseData,

    /**
     * Indicates whether data reporting initialization is in progress.
     *
     * @type {boolean}
     */
    isInitialisingData,

    /**
     * Runs an engine query on a project.
     *
     * @function RunEngineQuery
     * @param {string} instanceID - The instance ID of the project.
     * @param {string} query - The query string to run.
     * @returns {Promise<Object>} - The response from the API after running the query.
     * @throws Will throw an error if the query execution fails.
     */
    RunEngineQuery,

    /**
     * Indicates whether the engine query is in progress.
     *
     * @type {boolean}
     */
    isRunningEngineQuery,

    /**
     * Exports the project to an HTML file.
     *
     * @function exportToHTML
     * @param {string} id - The project ID.
     * @param {Object} options - The options for the export.
     * @param {string} filename - The name of the file to export.
     * @returns {Promise<Blob>} - The exported HTML file as a Blob.
     * @throws Will throw an error if the export fails.
     */
    exportToHTML,

    /**
     * Indicates whether the HTML export is in progress.
     *
     * @type {boolean}
     */
    isExportingHTML,

    /**
     * Exports the project to a JSON file.
     *
     * @function exportJSON
     * @param {string} instanceID - The instance ID.
     * @returns {Promise<Object>} - The response from the API after exporting to JSON.
     * @throws Will throw an error if the export fails.
     */
    exportJSON,

    /**
     * Indicates whether the JSON export is in progress.
     *
     * @type {boolean}
     */
    isExportingJSON,

    /**
     * Exports the project to an SQL file.
     *
     * @function exportSQL
     * @param {string} instanceID - The instance ID.
     * @returns {Promise<Object>} - The response from the API after exporting to SQL.
     * @throws Will throw an error if the export fails.
     */
    exportSQL,

    /**
     * Indicates whether the SQL export is in progress.
     *
     * @type {boolean}
     */
    isExportingSQL,

    /**
     * Exports the project to an SPS file.
     *
     * @function exportSPS
     * @param {string} instanceID - The instance ID.
     * @returns {Promise<Object>} - The response from the API after exporting to SPS.
     * @throws Will throw an error if the export fails.
     */
    exportSPS,

    /**
     * Indicates whether the SPS export is in progress.
     *
     * @type {boolean}
     */
    isExportingSPS,
  }
}

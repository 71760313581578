import useMediaQuery from "utils/useMediaQuery"

import DesktopHeader from "./DesktopHeader"

/**
 * Represents the header component that adapts its layout based on screen size.
 * @module Header
 * @returns {JSX.Element} The JSX element representing the header component.
 */
const Header = () => {
  /**
   * A hook that determines whether the current screen size is desktop or not.
   * @type {boolean}
   */
  let isDesktop = useMediaQuery("(min-width: 1080px)")

  //TODO Add Mobile Header

  /**
   * Renders the appropriate header component based on the screen size.
   * @returns {JSX.Element} The JSX element representing either the desktop header or an empty fragment.
   */
  return isDesktop ? <DesktopHeader /> : <DesktopHeader />
}

export default Header

import useApiCall from "hooks/useApiCall"
import {
  documentUpload,
  Availabledocuments,
  documentDelete,
  documentDownload,
} from "api/Repos/documents"

/**
 * Custom hook `useDocument` for handling document-related operations.
 *
 * This hook encapsulates the logic for uploading documents and retrieving project documents from the server.
 * It leverages the `useApiCall` hook for managing the API calls and loading states.
 *
 * @returns {Object} - An object containing functions for document operations and their loading states.
 *
 * @example
 * const { uploadDocument, isUploading, getProjectDocuments, isGettingProjectDocuments } = useDocument();
 * const handleUpload = async (file) => {
 *   const response = await uploadDocument('document_id', file.name, file);
 *   console.log(response);
 * };
 */
export const useDocument = () => {
  const [uploadDocument, isUploading] = useApiCall(documentUpload)
  const [deleteDocument, isDeleting] = useApiCall(documentDelete)
  const [downloadDocument, isDownloading] = useApiCall(documentDownload)
  const [getProjectDocuments, isGettingProjectDocuments] =
    useApiCall(Availabledocuments)

  return {
    uploadDocument,
    isUploading,
    getProjectDocuments,
    isGettingProjectDocuments,
    deleteDocument,
    isDeleting,
    downloadDocument,
    isDownloading,
  }
}

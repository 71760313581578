import { Layout, Menu } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faFolder } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"

/**
 * Represents the side menu component.
 * @returns {JSX.Element} The JSX element representing the side menu component.
 */
const SideMenu = (props) => {
  const { getClaim } = useKindeAuth()
  const userRoles = getClaim("roles")?.value || []

  /**
   * Destructures Sider component from Layout.
   */
  const { Sider } = Layout
  const navigate = useNavigate()

  /**
   * An array of menu items for the side menu.
   * @type {Object[]}
   */
  const sideMenuItems = [
    {
      /**
       * Key for the "home" menu item.
       * @type {string}
       */
      key: "/home",
      /**
       * Icon for the "home" menu item.
       * @type {JSX.Element}
       */
      icon: <FontAwesomeIcon className="h-8 text-black" icon={faFolder} />,
      /**
       * Click handler for the "home" menu item.
       */
      onClick: () => {
        navigate("/home")
      },
    },
  ]

  if (userRoles.some((role) => role.name === "super admin")) {
    sideMenuItems.push({
      /**
       * Key for the "superadmin" menu item.
       * @type {string}
       */
      key: "/superadmin",
      /**
       * Icon for the "superadmin" menu item.
       * @type {JSX.Element}
       */
      icon: <FontAwesomeIcon className="h-8 text-black" icon={faCog} />,
      /**
       * Click handler for the "superadmin" menu item.
       */
      onClick: () => {
        navigate("/superadmin")
      },
    })
  }

  /**
   * Renders the side menu component.
   * @returns {JSX.Element} The JSX element representing the side menu.
   */
  return (
    <Sider width={80}>
      <Menu
        mode="inline"
        defaultSelectedKeys={["/home"]}
        selectedKeys={props.current}
        className="side-nav h-full"
        items={sideMenuItems}
      />
    </Sider>
  )
}

export default SideMenu

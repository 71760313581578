import { useRecoilValue } from "recoil"
import { message, Table } from "antd"

import { useDataImport } from "hooks/useDataImport"
import DataImport from "../DataImport.component"
import DataQButton from "components/UI/Buttons/DataQButton"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"
import LoadingSkeleton from "components/UI/LoadingSkeleton/LoadingSkeleton"

import { projectAtom } from "atoms/projectAtom"
import handleSequenceError from "utils/handleSequenceError"

/**
 * DataImportTable component renders a table displaying data import status and actions
 * for a given project. It allows users to delete uploaded files or upload missing files.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data object containing the list of data files.
 * @param {boolean} props.isLoading - The loading state for the table.
 * @param {Function} props.refresh - The function to refresh the data after an action.
 * @returns {React.ReactElement} The rendered DataImportTable component.
 */
const DataImportTable = (props) => {
  const { dataFiles, isLoading, refresh } = props
  const { deleteDataImportFile } = useDataImport()
  const project = useRecoilValue(projectAtom)

  const columns = [
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType",
      width: 100,
    },
    {
      title: "Uploaded",
      dataIndex: "isUploaded",
      key: "isUploaded",
      width: 50,
      render: (_, record) => (
        <div>
          <p>{record.isUploaded ? "Yes" : "No"}</p>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div>
          {record.isUploaded ? (
            <DataQButton onClick={() => deleteFile(record.type)} type="danger">
              Delete
            </DataQButton>
          ) : (
            <DataImport
              name={`Upload ${record.fileType}`}
              refresh={refresh}
              type={record.type}
            />
          )}
        </div>
      ),
    },
  ]

  const deleteFile = async (type) => {
    try {
      const res = await deleteDataImportFile(
        project.id,
        project.Created_by,
        type
      )
      if (handleSequenceError(res, "Error Deleting Data File!")) {
        message.success("Data File Deleted Successfully!")
        refresh()
      }
    } catch (error) {
      ErrorNotification("Error deleting data file!", error.message)
    }
  }

  return (
    <Table
      size="small"
      className="w-full"
      rowKey="key"
      dataSource={dataFiles || []}
      columns={columns}
      pagination={false}
      locale={{
        emptyText: isLoading ? (
          <LoadingSkeleton type="table" columns={columns} />
        ) : (
          "No data"
        ),
      }}
    />
  )
}

export default DataImportTable

import axios from "axios"

import { ErrorNotification } from "../../components/UI/Notifications/NotificationTemplate.component"

// Main Axios instance
const axiosInstance = () => {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  })
}

let useThisAxios = axiosInstance()

useThisAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    ErrorNotification(error.name, error.message)
  }
)
export default useThisAxios

import { Helmet, HelmetProvider } from "react-helmet-async"

import SuperadminContainer from "containers/Superadmin/Superadmin.container"

const Superadmin = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Data Q - Superadmin Panel</title>
        </Helmet>
        <SuperadminContainer />
      </HelmetProvider>
    </>
  )
}

export default Superadmin

import { Layout, Menu, Avatar } from "antd"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleArrowRight,
  // faPenToSquare,
} from "@fortawesome/free-solid-svg-icons"

import { getFirstLetters } from "utils/getFirstLetters"
/**
 * Represents the desktop header component.
 * @returns {JSX.Element} The JSX element representing the desktop header component.
 */

const DesktopHeader = (props) => {
  const { Header } = Layout
  const { logout, user } = useKindeAuth()
  /**
   * An array of menu items for the profile dropdown.
   * @type {Object[]}
   * @property {string} key - The unique key for the menu item.
   * @property {string} label - The display label for the menu item.
   * @property {JSX.Element} icon - The icon component for the menu item.
   * @property {Object[]} [children] - Submenu items for the menu item.
   * @property {Function} [onClick] - Click handler for the menu item.
   */
  const menuItems = [
    {
      key: "unit-switcher",
      icon: <></>,
    },
    {
      key: "profile",
      label: `${user.given_name}`,
      icon: (
        <Avatar className="p-5">
          {getFirstLetters([user.given_name, user.family_name])}
        </Avatar>
      ),
      children: [
        // {
        //   label: "Settings",
        //   key: "settings",
        //   icon: (
        //     <FontAwesomeIcon
        //       className="flex flex-shrink-0 h-4 text-black mt-1 mr-2"
        //       icon={faPenToSquare}
        //     />
        //   ),
        // },
        {
          label: "Logout",
          key: "logout",
          onClick: () => {
            logout()
          },
          icon: (
            <FontAwesomeIcon
              className="flex flex-shrink-0 h-4 text-black mt-1 mr-2"
              icon={faCircleArrowRight}
            />
          ),
        },
      ],
    },
  ]

  /**
   * Renders the desktop header component.
   * @returns {JSX.Element} The JSX element representing the desktop header.
   */
  return (
    <Header className="site-layout-background flex p-0 bg-white">
      <img
        src="/assets/images/data-q-logo-black-n-blue.png"
        alt="logo"
        className="w-48 p-2"
      />
      <Menu
        mode={props.mode === undefined ? "horizontal" : props.mode}
        className="desktop-nav w-full"
        items={menuItems}
      />
    </Header>
  )
}

export default DesktopHeader

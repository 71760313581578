import useApiCall from "hooks/useApiCall"

import {
  checkQuestionnaire,
  getQuestionnaire,
  saveQuestionnaire,
  lockQuestionnaire,
  unlockQuestionnaire,
  persistQuestionnaireLock,
} from "api/Repos/questionnaire"

/**
 * Custom hook `useQuestionnaire` for fetching and saving questionnaire data by ID.
 *
 * This hook encapsulates the logic for asynchronously fetching and saving questionnaire data from/to a server.
 * It leverages the `useApiCall` hook for managing the API calls and loading states.
 *
 * @returns {Object} - An object containing the `getQuestionnaireByID` and `saveQuestionnaireByID` functions and their loading states.
 *
 * @example
 * const { getQuestionnaireByID, isLoading, saveQuestionnaireByID } = useQuestionnaire();
 * useEffect(() => {
 *   const fetchData = async () => {
 *     const data = await getQuestionnaireByID('123');
 *     console.log(data);
 *   };
 *   fetchData();
 * }, []);
 */
export const useQuestionnaire = () => {
  const [getQuestionnaireByID, isLoadingQuestionnaire] =
    useApiCall(getQuestionnaire)
  const [saveQuestionnaireByID, isSavingQuestionnaire] =
    useApiCall(saveQuestionnaire)
  const [lockQuestionnaireByID, isLockingQuestionnaire] =
    useApiCall(lockQuestionnaire)
  const [unlockQuestionnaireByID, isUnLockingQuestionnaire] =
    useApiCall(unlockQuestionnaire)
  const [extendQuestionnaireLock, isExtendingQuestionnaireLock] = useApiCall(
    persistQuestionnaireLock
  )
  const [checkQuestionnaireByID, isCheckingQuestionnaire] =
    useApiCall(checkQuestionnaire)

  return {
    getQuestionnaireByID,
    isLoadingQuestionnaire,
    saveQuestionnaireByID,
    isSavingQuestionnaire,
    checkQuestionnaireByID,
    isCheckingQuestionnaire,
    lockQuestionnaireByID,
    isLockingQuestionnaire,
    unlockQuestionnaireByID,
    isUnLockingQuestionnaire,
    extendQuestionnaireLock,
    isExtendingQuestionnaireLock,
  }
}

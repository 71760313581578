/**
 * Home container component responsible for acting as a container for the homepage.
 * @namespace HomeContainer
 * @memberOf Home
 */
import { useEffect, useState, useCallback } from "react"
import { useRecoilValue } from "recoil"

import ProjectDisplay from "components/Home/Projects/ProjectDisplay.component"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

import { useProjects } from "hooks/useProjects"
import { useUser } from "hooks/useUser"

import { userAtom } from "atoms/userAtom"

import handleSequenceError from "utils/handleSequenceError"

/**
 * Renders the Home container component.
 * This component serves as the main page of the application, showcasing a list of projects.
 * It utilizes the ProjectDisplay component to render the projects passed to it via props.
 *
 * @function HomeContainer
 * @memberof HomeContainer
 * @returns {React.ReactNode} The HomeContainer component rendered as a React node.
 */
const HomeContainer = () => {
  const { getUserProjects, isLoadingProjects } = useProjects()
  const { getUserData } = useUser()
  const user = useRecoilValue(userAtom)

  const [filter, setFilter] = useState("owned")
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (!user.username || !user.unit) {
      getUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.username && user.unit) {
      fetchProjects()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username, user.unit, filter])

  /**
   * Fetches the user's projects and updates the state.
   * @async
   * @function fetchProjects
   * @returns {Promise<void>}
   */
  const fetchProjects = useCallback(async () => {
    try {
      const res = await getUserProjects(filter)
      if (handleSequenceError(res, "Error Retrieving Projects!")) {
        setDataSource(res.result)
      }
    } catch (error) {
      ErrorNotification("Error Fetching Projects!", error.message)
    }
  }, [filter, getUserProjects])

  return (
    <div id="home" className="flex flex-col">
      <ProjectDisplay
        filter={filter}
        setFilter={setFilter}
        isLoading={isLoadingProjects}
        getProjects={fetchProjects}
        projects={dataSource}
      />
    </div>
  )
}

export default HomeContainer

import { useState, useEffect } from "react"

/**
 * A custom hook to match media queries.
 * @param {string} query - The media query string.
 * @returns {boolean} - Whether the media query matches the current viewport or not.
 */
const useMediaQuery = (query) => {
  /**
   * State to hold the match result of the media query.
   * @type {[boolean, function]}
   */
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    /**
     * The media query object.
     * @type {MediaQueryList}
     */
    const media = window.matchMedia(query)

    /**
     * Handler to update the matches state based on the media query result.
     */
    const updateMatches = () => {
      setMatches(media.matches)
    }

    // Initial check and event listener attachment
    updateMatches()
    media.addEventListener("change", updateMatches)

    // Cleanup function to remove the event listener
    return () => {
      media.removeEventListener("change", updateMatches)
    }
  }, [query])

  return matches
}

export default useMediaQuery

import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

/**
 * Handles sequence errors by checking the response, which can be an empty string, a non-empty string, or an object, for an error message.
 * If an error is found, it displays a notification with the provided error message.
 *
 * @function handleSequenceError
 * @param {string|object} res - The response from an API call, which can be an empty string, a non-empty string, or an object.
 * @param {string} [defaultTitle="Error"] - The default title for the error notification.
 * @returns {boolean} Returns false if an error is found and handled, otherwise true.
 */
const handleSequenceError = (res, defaultTitle = "Error") => {
  // If res is an object with an "Err msg" key
  if (typeof res === "object" && res !== null) {
    if ("Err msg" in res) {
      const errorMsg = res["Err msg"]
      ErrorNotification(defaultTitle, errorMsg)
      return false
    }
    return true // No error message found in the object
  }

  // If res is an empty string, assume there's an error
  if (typeof res === "string" && res.trim() === "") {
    ErrorNotification(defaultTitle, "An unknown error occurred.")
    return false
  }

  // In case res is neither an object nor a string (shouldn't normally happen)
  return true
}

export default handleSequenceError

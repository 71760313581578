import React, { useState } from "react"
import { Tabs, Tooltip, message, Switch, Select } from "antd"
import {
  faCopy,
  faFileExcel,
  faDownload,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import StatisticsTable from "components/UI/Table/StatisticTable"
import BarChart from "components/UI/Charts/BarChart"
import PieChart from "components/UI/Charts/PieChart"
import { convertDataToTable } from "utils/convertDataToTable"
import { exportToExcel } from "utils/exportToExcel"
import { downloadJSON } from "utils/downloadJSON"
import { jsonPretty } from "utils/jsonPretty"

const { Option } = Select

/**
 * OutputTabs component to render tabbed output from a result object.
 * It supports rendering data as tables, charts, or JSON, and includes options to copy, export, or download the data.
 *
 * @param {Object} props
 * @param {Object} props.result - The result object containing the data and other information.
 * @param {Array} props.result.output - The output array where each item represents a table or chart.
 * @param {string} [props.result.log] - The log associated with the result.
 * @returns {JSX.Element|null} A component that renders the result in tabbed format, or null if no result is available.
 */
const OutputTabs = (props) => {
  const { result } = props
  const [chartActive, setChartActive] = useState(false)
  const [chartType, setChartType] = useState("bar")

  if (!result || !result.log) {
    return null
  }

  if (result.output === undefined || result.output.length === 0) {
    return (
      <p className="p-2">
        No Output in result. Please check if query has output. See log below
      </p>
    )
  }

  /**
   * Copies the given content to the clipboard and displays a success message.
   *
   * @param {Object} content - The content to copy to the clipboard.
   */
  const CopyToClipboard = (content) => {
    const contentToCopy = jsonPretty(content)
    navigator.clipboard.writeText(contentToCopy)
    message.success("Output copied to clipboard")
  }

  const tabItems = result.output.map((item, index) => {
    const isRtable = item.format !== "json" && Array.isArray(item.content.data)
    const content = isRtable
      ? convertDataToTable(item.content)
      : jsonPretty(item.content)

    const contentTooLong = !isRtable && content.length > 1000000

    return {
      key: `${index + 1}`,
      label: item.label || `Table ${index + 1}`,
      children: (
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-between items-center p-3 shadow-sm">
            <div className="flex items-center space-x-4">
              {isRtable && (
                <>
                  <Switch
                    style={{ backgroundColor: "#36c3ed" }}
                    checkedChildren="Chart"
                    unCheckedChildren="Table"
                    checked={chartActive}
                    onChange={(checked) => setChartActive(checked)}
                  />
                  {/* {chartActive && (
                    <Select
                      value={chartType}
                      onChange={(value) => setChartType(value)}
                      style={{ width: 180 }}
                    >
                      <Option value="bar">Bar Chart</Option>
                      <Option value="pie-category">
                        Pie Chart (Categories)
                      </Option>
                      <Option value="pie-subcategory">
                        Pie Chart (Sub-categories)
                      </Option>
                    </Select>
                  )} */}
                </>
              )}
            </div>
            <div className="flex space-x-3">
              <Tooltip title="Copy to clipboard">
                <FontAwesomeIcon
                  icon={faCopy}
                  color="#36c3ed"
                  className="cursor-pointer"
                  onClick={() => CopyToClipboard(item.content)}
                />
              </Tooltip>
              {isRtable && (
                <Tooltip title="Export to Excel">
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    color="#36c3ed"
                    className="cursor-pointer"
                    onClick={() =>
                      exportToExcel(
                        item.content.data,
                        item.label || `Table_${index + 1}`
                      )
                    }
                  />
                </Tooltip>
              )}
              {!isRtable && contentTooLong && (
                <Tooltip title="Download JSON">
                  <FontAwesomeIcon
                    icon={faDownload}
                    color="#36c3ed"
                    className="cursor-pointer"
                    onClick={() =>
                      downloadJSON(
                        item.content,
                        item.label || `Output_${index + 1}`
                      )
                    }
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="h-full overflow-auto px-2">
            {isRtable ? (
              chartActive ? (
                chartType === "bar" ? (
                  <BarChart
                    isChart={!content.hasSubHeaders}
                    data={content.barChartData.data}
                    keys={content.barChartData.keys}
                    statisticTypes={content.barChartData.statisticTypes}
                    xAxisLabel={content.barChartData.xAxisLabel}
                  />
                ) : (
                  <PieChart
                    isChart={!content.hasSubHeaders}
                    data={
                      chartType === "pie-category"
                        ? content.pieChartData.categoryData
                        : content.pieChartData.subCategoryData
                    }
                    statisticTypes={content.pieChartData.statisticTypes}
                    chartType={chartType}
                  />
                )
              ) : (
                <StatisticsTable transformedData={content} />
              )
            ) : contentTooLong ? (
              <p className="p-2">
                Content is too large to display. Click the download button to
                download the full content.
              </p>
            ) : (
              <pre className="m-0 p-0">{content}</pre>
            )}
          </div>
        </div>
      ),
    }
  })

  return (
    <div className="h-full w-full overflow-auto">
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        className="data-reporting-tabs"
      />
    </div>
  )
}

export default OutputTabs

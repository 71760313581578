import { notification } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleExclamation,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons"

/**
 * Display an error notification.
 * @param {string} message - The message to be displayed in the notification.
 * @param {string} desc - The description of the notification.
 * @param {string} placement - The placement of the notification. One of ["topLeft", "topRight", "bottomLeft", "bottomRight"].
 * @returns {void}
 */
const ErrorNotification = (message, desc, placement) => {
  notification.open({
    message: message,
    duration: 5,
    description: desc,
    placement: placement,
    icon: (
      <FontAwesomeIcon
        icon={faCircleExclamation}
        style={{ color: "#A91B0D", height: "1.2em" }}
      />
    ),
  })
}

/**
 * Display a success notification.
 * @param {string} message - The message to be displayed in the notification.
 * @param {string} desc - The description of the notification.
 * @param {string} placement - The placement of the notification. One of ["topLeft", "topRight", "bottomLeft", "bottomRight"].
 * @returns {void}
 */
const SuccessNotification = (message, desc, placement) => {
  notification.open({
    message: message,
    duration: 5,
    description: desc,
    placement: placement,
    icon: (
      <FontAwesomeIcon
        icon={faCircleCheck}
        style={{ color: "#41c219", height: "1.2em" }}
      />
    ),
  })
}

export { ErrorNotification, SuccessNotification }

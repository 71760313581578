import DataQButton from "components/UI/Buttons/DataQButton"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"

/**
 * LoginDisplay component displays the login and registration buttons using Kinde authentication.
 * @returns {JSX.Element} The rendered JSX element.
 */
const LoginDisplay = () => {
  const {
    login,
    //register
  } = useKindeAuth()

  return (
    <div className="flex flex-col h-full">
      <div className="mb-3">
        <img
          src="/assets/images/data-q-logo-black-n-blue.png"
          alt="logo"
          className="w-60"
        />
      </div>
      <div className="flex flex-col space-y-2">
        {/* <DataQButton type="primary" className="h-10 w-full" onClick={register}>
          Sign up
        </DataQButton> */}
        <DataQButton type="primary" className="h-10 w-full" onClick={login}>
          Sign In
        </DataQButton>
      </div>
    </div>
  )
}

export default LoginDisplay

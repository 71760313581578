/**
 * Converts a file to a base64 string.
 *
 * @param {File} file - The file to convert.
 * @returns {Promise<string>} The base64 string representation of the file.
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split(",")[1])
    reader.onerror = (error) => reject(error)
  })
}

import { useEffect, useState } from "react"
import { useDocument } from "hooks/useDocument"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import DocumentTable from "components/Home/Documents/DocumentTable/DocumentTable.component"
import DocumentsHeader from "components/Home/Documents/DocumentsHeader.component"

import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"
import handleSequenceError from "utils/handleSequenceError"
/**
 * Container component for the documents section.
 *
 * This component includes a header with a search functionality and a table displaying documents.
 *
 * @returns {JSX.Element} The rendered container component.
 */
const DocumentsContainer = () => {
  const project = useRecoilValue(projectAtom)
  const { getProjectDocuments, isGettingProjectDocuments } = useDocument()
  const [documents, setDocuments] = useState([])
  const [filter, setFilter] = useState("")

  useEffect(() => {
    fetchDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const fetchDocuments = async () => {
    try {
      const res = await getProjectDocuments(
        project.id,
        project.Created_by,
        filter
      )
      if (!res) {
        setDocuments([])
        return
      }
      if (handleSequenceError(res, "Error Retrieving Documents!")) {
        let docArray = res.split("\n")
        setDocuments(docArray)
      }
    } catch (error) {
      ErrorNotification("Error Fetching Documents!", error.message)
    }
  }

  /**
   * Handles the search action.
   *
   * @param {string} value - The search input value.
   * @param {Event} _e - The event object (unused).
 
   */
  const onSearch = (value, _e) => setFilter(value)

  return (
    <div className="flex flex-col h-full overflow-y-auto w-full">
      <DocumentsHeader
        refresh={fetchDocuments}
        project={project}
        onSearch={onSearch}
      />
      <DocumentTable
        refresh={fetchDocuments}
        project={project}
        isLoading={isGettingProjectDocuments}
        documents={documents}
      />
    </div>
  )
}

export default DocumentsContainer

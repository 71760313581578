import { useState } from "react"
import { Modal, Typography } from "antd"
import DataQButton from "components/UI/Buttons/DataQButton"
import {
  ErrorNotification,
  SuccessNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import CreateVariantForm from "./CreateVariantForm.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

/**
 * CreateVariantModal is a modal component for creating a new variant in a project.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.project - The project data.
 * @param {function} props.insertVariantText - Function to insert variant text into the editor.
 * @param {Array} props.variants - The list of existing variants.
 * @param {Function} props.setVariants - Function to update the variants list.
 * @param {Object} props.selectedVariant - The currently selected variant.
 * @param {Function} props.setSelectedVariant - Function to set the selected variant.
 * @param {Function} props.refresh - Function to refresh the project data.
 * @param {Function} props.saveQuestionnaire - Function to save the questionnaire.
 * @returns {JSX.Element} The rendered CreateVariantModal component.
 */
const CreateVariantModal = (props) => {
  const {
    project,
    insertVariantText,
    variants,
    setVariants,
    selectedVariant,
    setSelectedVariant,
    refresh,
    saveQuestionnaire,
  } = props
  const { Title } = Typography
  const [isModalVisible, setIsModalVisible] = useState(false)

  /**
   * Callback function to handle successful variant creation.
   *
   * @function createVariantCallback
   */
  const createVariantCallback = () => {
    SuccessNotification(
      "Variant Created Successfully!",
      "The project has been created successfully."
    )
    saveQuestionnaire()
    refresh(project.id)
  }

  /**
   * Shows the modal for creating a new variant.
   * Displays an error notification if a non-master variant is selected.
   *
   * @function showModal
   */
  const showModal = () => {
    if (Object.keys(selectedVariant).length === 0) {
      setIsModalVisible(true)
    } else {
      ErrorNotification(
        "Can Only Create Variants in Master!",
        "Please select Master variant to create a new variant."
      )
    }
  }

  return (
    <>
      <DataQButton onClick={showModal} type="secondary" classname="text-black">
        New Variant
        <FontAwesomeIcon icon={faPlusCircle} className="ml-2" />
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <Title style={{ marginBottom: "0.5em" }} level={4}>
          Create New Variant
        </Title>
        <CreateVariantForm
          setVariants={setVariants}
          project={project}
          variants={variants}
          createVariantCallback={createVariantCallback}
          setIsModalVisible={setIsModalVisible}
          insertVariantText={insertVariantText}
          setSelectedVariant={setSelectedVariant}
        />
      </Modal>
    </>
  )
}

export default CreateVariantModal

/**
 * Generates a list of question submenus.
 *
 * @param {Array<Object>} subItems - List of sub-items.
 * @returns {Array<Object>} Generated question submenus.
 */
const generateQuestionSubmenus = (subItems) => {
  return subItems.map((item) => ({
    type: "submenu",
    label: item.label,
    key: item.key,
    children: item.children.map((child) => ({
      label: child.label,
      key: `${item.key}_${child.key}`,
    })),
  }))
}

const questionTypes = [
  {
    label: "Single Select",
    key: "single_select",
    children: [
      { label: "Basic", key: "basic" },
      { label: "User Predefined List", key: "user_predefined_list" },
      {
        label: "Extended With Predefined List",
        key: "extended_with_predefined_list",
      },
    ],
  },
  {
    label: "Multiple Select",
    key: "multiple_select",
    children: [
      { label: "Basic", key: "basic" },
      { label: "User Predefined List", key: "user_predefined_list" },
      {
        label: "Extended With Predefined List",
        key: "extended_with_predefined_list",
      },
    ],
  },
  {
    label: "Grid Single Select",
    key: "grid_single_select",
    children: [
      { label: "Basic", key: "basic" },
      { label: "User Predefined List", key: "user_predefined_list" },
      {
        label: "Extended With Predefined List",
        key: "extended_with_predefined_list",
      },
    ],
  },
  {
    label: "Grid Multiple Select",
    key: "grid_multiple_select",
    children: [
      { label: "Basic", key: "basic" },
      { label: "User Predefined List", key: "user_predefined_list" },
      {
        label: "Extended With Predefined List",
        key: "extended_with_predefined_list",
      },
    ],
  },
]

/**
 * Menu items configuration for the application.
 * @type {Array<Object>}
 */
const items = [
  {
    label: "File",
    key: "file",
    children: [
      // { label: "New Variant", key: "new_variant" },
      { label: "Export to Decipher XML", key: "export_to_decipher_xml" },
      // {
      //   type: "submenu",
      //   label: "Translations",
      //   key: "translations",
      //   children: [
      //     {
      //       label: "Download Translations File",
      //       key: "download_translations_file",
      //     },
      //     {
      //       label: "Upload Translations File",
      //       key: "upload_translations_file",
      //     },
      //   ],
      // },
      {
        label: "Download Data Map Template",
        key: "download_data_map_template",
      },
    ],
  },
  {
    label: "Insert",
    key: "insert",
    children: [
      // { label: "Include Instructions", key: "include_instructions" },
      { label: "Note", key: "note" },
      { label: "Section", key: "section" },
      { label: "Text", key: "text" },
      {
        type: "submenu",
        label: "List",
        key: "list",
        children: [
          { label: "Simple List", key: "simple_list" },
          {
            label: "List With Various Representations",
            key: "list_with_various_representations",
          },
          { label: "Exception List", key: "exception_list" },
        ],
      },
      {
        type: "submenu",
        label: "Question",
        key: "question",
        children: [
          {
            type: "submenu",
            label: "Fill-in",
            key: "fill_in",
            children: [
              { label: "Number", key: "fill_in_number" },
              { label: "Text", key: "fill_in_text" },
            ],
          },
          ...generateQuestionSubmenus(questionTypes),
        ],
      },
      {
        type: "submenu",
        label: "Special",
        key: "special",
        children: [
          { label: "Hidden Question", key: "hidden_question" },
          { label: "Data", key: "data" },
          {
            type: "submenu",
            label: "Qnr Changes in a Variant",
            key: "qnr_changes_in_a_variant",
            children: [
              { label: "Hide a Question", key: "hide_a_question" },
              { label: "Extend a List", key: "extend_a_list" },
              { label: "Trim a List", key: "trim_a_list" },
              { label: "Reposition Questions", key: "reposition_questions" },
            ],
          },
        ],
      },
      { label: "Template", key: "template" },
    ],
  },
]

export default items

import axios from "../request"

/**
 * Downloads a data map file in CSV format.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data map to be downloaded.
 * @param {string} filename - The desired filename for the downloaded CSV file.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the download fails.
 */
export async function downloadDatamap(token, user, unit, role, id, filename) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // This is important to handle the file download
    }
    const response = await axios.post(`/download-datamap`, { id: id }, config)

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${filename}.map.csv`) // Set the filename
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response
  } catch (error) {
    throw error
  }
}

/**
 * Initializes data reporting for project.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the project to initialize data reporting for.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the initialization fails.
 */
export async function InitialiseDataReporting(token, user, unit, role, id) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.get(`/initialise-data?id=${id}`, config)
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Runs a query on a specific engine instance.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} instanceID - The identifier of the query instance to run the query on. Returned from initialise-data.
 * @param {string} query - The query to be executed.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the query execution fails.
 */
export async function RunQuery(token, user, unit, role, instanceID, query) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
    }
    const response = await axios.post(
      `/query-run?${instanceID}`,
      { query: query },
      config
    )
    return response
  } catch (error) {
    throw error
  }
}

import { useState } from "react"
import { useRecoilValue } from "recoil"
import { useTemplates } from "hooks/useTemplates"

import TemplateList from "./TemplateWizardSteps/TemplateList.component"
import SelectedTemplateForm from "./TemplateWizardSteps/SelectTemplateForm.component"
import PreviewTemplate from "./TemplateWizardSteps/PreviewTemplate.component"
import DataQButton from "components/UI/Buttons/DataQButton"

import { projectAtom } from "atoms/projectAtom"
import TemplateFiles from "./TemplateWizardSteps/TemplateFiles.component"

/**
 * TemplateWizard component provides a step-by-step interface for selecting,
 * configuring, and previewing templates in the Data(Q) system.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setTemplateModalVisible - Function to toggle the visibility of the modal.
 * @param {Array} props.templateFiles - The list of available template files.
 * @param {Object} props.editor - The editor instance used to insert the final template content.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.qnrEmpty - Indicates if the questionnaire is empty.
 * @param {Function} props.setCurrentStep - Function to set the current step in the wizard.
 * @param {number} props.currentStep - The current step number in the wizard.
 * @returns {JSX.Element} The rendered TemplateWizard component.
 */
const TemplateWizard = (props) => {
  const {
    setTemplateModalVisible,
    templateFiles,
    editor,
    inMaster,
    qnrEmpty,
    setCurrentStep,
    currentStep,
  } = props

  const project = useRecoilValue(projectAtom)
  const {
    listTemplatesFromFile,
    listingTemplatesFromFile,
    parseSelectedTemplate,
    parsingSelectedTemplate,
    mergeTemplateWithUserInputs,
  } = useTemplates()

  const [templates, setTemplates] = useState([])
  const [parsedTemplate, setParsedTemplate] = useState({ sections: [] })
  const [selectedTemplateFile, setSelectedTemplateFile] = useState({})
  const [userInputs, setUserInputs] = useState({
    "template-label": "",
    inputs: [],
  })
  const [templateOutput, setTemplateOutput] = useState("")

  /**
   * Hides the modal and resets the state.
   *
   * @function hideModal
   */
  const hideModal = () => {
    setTemplateModalVisible(false)
  }

  /**
   * Handles the selection of a template file and lists the available templates.
   *
   * @async
   * @function handleTemplateFilesItemClick
   * @param {Object} templateFile - The selected template file.
   * @returns {Promise<void>}
   */
  const handleTemplateFilesItemClick = async (templateFile) => {
    setCurrentStep(1)
    try {
      setSelectedTemplateFile(templateFile)
      const templateRes = await listTemplatesFromFile(templateFile)
      setTemplates(templateRes.templateList.result)
    } catch (error) {
      console.error("Error parsing template:", error)
    }
  }

  /**
   * Handles the selection of a specific template and parses it.
   *
   * @async
   * @function handleTemplateItemClick
   * @param {string} text - The selected template's label or identifier.
   * @returns {Promise<void>}
   */
  const handleTemplateItemClick = async (text) => {
    setCurrentStep(2)
    const parseOptions = {
      inMaster,
      qnrEmpty,
    }
    try {
      const parseResponse = await parseSelectedTemplate(
        project.id,
        selectedTemplateFile,
        text,
        parseOptions
      )
      setParsedTemplate(parseResponse.templateParse.result || { sections: [] })
      setUserInputs((prevUserInputs) => ({
        ...prevUserInputs,
        "template-label": text,
      }))
    } catch (error) {
      console.error("Error parsing template:", error)
    }
  }

  /**
   * Handles the "Next" button click to proceed to the next step or finalize the process.
   *
   * @async
   * @function handleNext
   * @returns {Promise<void>}
   */
  const handleNext = async () => {
    const mergeOptions = {
      inMaster,
      qnrEmpty,
    }

    if (currentStep === parsedTemplate.sections.length + 2) {
      editor.commands.insertContent(templateOutput)
      hideModal()
      return
    }

    const currentSection = parsedTemplate.sections[currentStep - 2]

    if (currentSection) {
      if (currentStep - 2 === parsedTemplate.sections.length - 1) {
        const mergeRes = await mergeTemplateWithUserInputs(
          userInputs,
          selectedTemplateFile,
          mergeOptions
        )
        setTemplateOutput(mergeRes)
        setCurrentStep(parsedTemplate.sections.length + 2)
      } else {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  /**
   * Handles the "Previous" button click to return to the previous step.
   *
   * @function handlePrev
   */
  const handlePrev = () => {
    if (currentStep === 2) {
      setUserInputs({
        "template-label": "",
        inputs: [],
      })
      setCurrentStep(1)
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  /**
   * Updates the value of a user input.
   *
   * @function updateUserInputValue
   * @param {string} label - The label of the input field.
   * @param {string} value - The value to be set for the input field.
   */
  const updateUserInputValue = (label, value) => {
    setUserInputs((prevUserInputs) => {
      const existingInputIndex = prevUserInputs.inputs.findIndex(
        (input) => input.label === label
      )

      if (existingInputIndex >= 0) {
        const updatedInputs = [...prevUserInputs.inputs]
        updatedInputs[existingInputIndex] = { label, value }
        return {
          ...prevUserInputs,
          inputs: updatedInputs,
        }
      } else {
        return {
          ...prevUserInputs,
          inputs: [...prevUserInputs.inputs, { label, value }],
        }
      }
    })
  }

  /**
   * Renders the content for the current step in the wizard.
   *
   * @function renderStepContent
   * @returns {JSX.Element} The content for the current step.
   */
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <TemplateFiles
            templateFiles={templateFiles}
            onItemClick={handleTemplateFilesItemClick}
          />
        )
      case 1:
        return (
          <TemplateList
            templates={templates}
            listingTemplatesFromFile={listingTemplatesFromFile}
            onItemClick={handleTemplateItemClick}
          />
        )
      case parsedTemplate.sections.length + 2:
        return <PreviewTemplate templateOutput={templateOutput} />
      default:
        const currentSection = parsedTemplate.sections[currentStep - 2]
        return (
          <SelectedTemplateForm
            exceptions={parsedTemplate.information.exceptions}
            parsingSelectedTemplate={parsingSelectedTemplate}
            section={currentSection}
            values={userInputs.inputs.reduce((acc, input) => {
              acc[input.label] = input.value
              return acc
            }, {})}
            onChange={updateUserInputValue}
          />
        )
    }
  }

  return (
    <div className="flex flex-col mt-5">
      <div className="flex flex-col max-h-[800px] overflow-auto">
        {renderStepContent()}
      </div>
      <div className="ml-auto space-x-2 mt-5">
        {currentStep > 0 && (
          <DataQButton onClick={handlePrev} type="primary">
            Previous
          </DataQButton>
        )}
        {currentStep > 1 &&
          currentStep <= parsedTemplate.sections.length + 2 && (
            <DataQButton onClick={handleNext} type="primary">
              {currentStep === parsedTemplate.sections.length + 2
                ? `Finalise`
                : `Next`}
            </DataQButton>
          )}
        <DataQButton onClick={hideModal} type="secondary">
          Cancel
        </DataQButton>
      </div>
    </div>
  )
}

export default TemplateWizard

import { Extension } from "@tiptap/core"
import { Decoration, DecorationSet } from "prosemirror-view"
import { Plugin } from "prosemirror-state"
import keywordStyles from "./keywordStyles"

// Block keywords and their corresponding highlight classes
const blockKeywords = {
  "ADD LIST": "highlight-item",
  LIST: "highlight-item",
  NOTE: "highlight-note",
  HEADER: "highlight-item",
  ADD: "highlight-item",
  CHANGE: "highlight-item",
  "RANGE LIST": "highlight-item",
  PER: "highlight-item",
  "SELECT ONE FROM": "highlight-item",
  "SELECT MANY FROM": "highlight-item",
  "SELECT MULTIPLE FROM": "highlight-item",
  TRIM: "highlight-item",
  REMOVE: "highlight-item",
  EXTEND: "highlight-item",
}

// Sorted block keywords by length (longer first)
const sortedBlockKeywords = Object.keys(blockKeywords).sort(
  (a, b) => b.length - a.length
)

// Regular expressions for remark lines and property highlighting
const remarkRegex = /^(#|REMARK)\s*(.*)/gm
const propertyRegex = /\[\[(.*?)\]\]/g
const angleBracketRegex = /<<([^>>]*?)>>/g // New regex for <<>>

// Cache object for memoization
const memoizedResults = new Map()

/**
 * Handles special keywords within a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {Decoration[]} An array of decorations for the special keywords.
 */
const handleSpecialKeywords = (line, linePos) => {
  let decorations = []
  const specialKeywords = [
    "IF SELECTED",
    "ANY OF",
    "OR",
    "AND",
    "IS",
    "IS NOT",
    "IN",
  ]

  specialKeywords.forEach((keyword) => {
    const specialRegex = new RegExp(`\\b(${keyword})\\b`, "g")
    let match
    while ((match = specialRegex.exec(line)) !== null) {
      const startKeyword = linePos + match.index
      const endKeyword = startKeyword + match[0].length

      decorations.push(
        Decoration.inline(startKeyword, endKeyword, {
          class: keywordStyles[keyword]?.keywordClass || "highlight-special",
        })
      )
    }
  })

  return decorations
}

/**
 * Handles decorations for block keywords in a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {{decorations: Decoration[], insideBlock: string|null}} An object containing decorations and the current block class.
 */
const handleBlockKeyword = (line, linePos) => {
  const blockKeywordMatch = sortedBlockKeywords.find((keyword) =>
    line.trim().startsWith(keyword)
  )

  if (blockKeywordMatch) {
    const regex = new RegExp(`^(${blockKeywordMatch})`)
    const match = regex.exec(line)
    if (match) {
      const startKeyword = linePos + match.index
      const endKeyword = startKeyword + match[0].length

      const decorations = [
        Decoration.inline(startKeyword, endKeyword, {
          class: "highlight-bold",
        }),
      ]

      // Add block highlight class to the rest of the line
      const blockContentStart = endKeyword
      const blockContentEnd = linePos + line.length

      // Apply the block highlight class to the remaining content
      decorations.push(
        Decoration.inline(blockContentStart, blockContentEnd, {
          class: blockKeywords[blockKeywordMatch],
        })
      )

      return { decorations, insideBlock: blockKeywords[blockKeywordMatch] }
    }
  }

  return { decorations: [], insideBlock: null }
}

/**
 * Memoized function for handling general keywords.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {{decorations: Decoration[], lineContainsKeyword: boolean}} An object containing decorations and whether the line contains a keyword.
 */
const handleGeneralKeyword = (line, linePos) => {
  const cacheKey = `${linePos}:${line}`
  if (memoizedResults.has(cacheKey)) {
    return memoizedResults.get(cacheKey)
  }

  let decorations = []
  let lineContainsKeyword = false

  const keywords = Object.keys(keywordStyles).sort(
    (a, b) => b.split(" ").length - a.split(" ").length
  )

  keywords.forEach((keyword) => {
    const { keywordClass, contentClass } = keywordStyles[keyword]
    const regex =
      keyword === "Q" || keyword === "QUESTION" || keyword === "TEXT"
        ? new RegExp(`^(${keyword})(\\s+[^:]*)(:)?(.*)`, "g") // Ensure keyword is followed by a space or colon
        : keyword === "NOTE"
        ? new RegExp(`^(NOTE)\\s*(.*?):\\s*(.*)`, "g")
        : keyword === "DATA"
        ? new RegExp(`^(DATA)(\\s+.*)?`, "g")
        : new RegExp(`\\b(${keyword})(\\s.*)?`, "g")

    let match
    while ((match = regex.exec(line)) !== null) {
      lineContainsKeyword = true
      const startKeyword = linePos + match.index
      const endKeyword = startKeyword + match[1].length

      decorations.push(
        Decoration.inline(startKeyword, endKeyword, {
          class: keywordClass,
        })
      )

      if (
        (keyword === "Q" || keyword === "QUESTION" || keyword === "TEXT") &&
        match[3] === ":"
      ) {
        const startTarget = endKeyword
        const endTarget = startTarget + match[2].length

        decorations.push(
          Decoration.inline(startTarget, endTarget, {
            class: "highlight-item",
          })
        )

        const startContent = endTarget + 1
        const endContent = linePos + match.index + match[0].length

        decorations.push(
          Decoration.inline(startContent, endContent, {
            class: contentClass,
          })
        )
      } else if (match[2]) {
        const startContent = endKeyword
        const endContent = linePos + match.index + match[0].length

        decorations.push(
          Decoration.inline(startContent, endContent, {
            class: contentClass,
          })
        )
      }
    }
  })

  const result = { decorations, lineContainsKeyword }
  memoizedResults.set(cacheKey, result)
  return result
}

/**
 * Handles decorations for a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @param {string|null} insideBlock - The current block class if inside a block.
 * @returns {{decorations: Decoration[], insideBlock: string|null}} An object containing decorations and the current block class.
 */
const handleLineDecorations = (line, linePos, insideBlock) => {
  let decorations = []

  // Handle special keywords first
  decorations = decorations.concat(handleSpecialKeywords(line, linePos))

  // Handle block keywords
  let { decorations: blockDecorations, insideBlock: newInsideBlock } =
    handleBlockKeyword(line, linePos)
  decorations = decorations.concat(blockDecorations)

  if (insideBlock && !line.trim()) {
    decorations.push(
      Decoration.inline(linePos, linePos + line.length, {
        class: insideBlock,
      })
    )
    return { decorations, insideBlock }
  }

  if (!newInsideBlock) {
    const { decorations: generalDecorations, lineContainsKeyword } =
      handleGeneralKeyword(line, linePos)
    decorations = decorations.concat(generalDecorations)

    if (!lineContainsKeyword && line.trim()) {
      decorations.push(
        Decoration.inline(linePos, linePos + line.length, {
          class: insideBlock,
        })
      )
    } else {
      insideBlock = null
    }
  }

  return { decorations, insideBlock: newInsideBlock || insideBlock }
}

/**
 * Handles remark lines separately.
 * @param {Node} doc - The ProseMirror document node.
 * @param {Decoration[]} decorations - The existing decorations.
 * @returns {Decoration[]} The updated decorations.
 */
const handleRemarkLines = (doc, decorations) => {
  let newDecorations = [...decorations]

  doc.descendants((node, pos) => {
    if (node.isText && node.text) {
      let match
      while ((match = remarkRegex.exec(node.text)) !== null) {
        const startRemark = pos + match.index
        const endRemark = startRemark + match[0].length

        newDecorations = newDecorations.filter(
          (deco) => !(deco.from < endRemark && deco.to > startRemark)
        )

        newDecorations.push(
          Decoration.inline(startRemark, endRemark, {
            class: "highlight-remark",
          })
        )
      }
    }
  })

  return newDecorations
}

const FontChangeExtension = Extension.create({
  name: "syntaxHighlighting",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations(state) {
            let decorations = []
            const { doc } = state
            let insideBlock = null

            doc.descendants((node, pos) => {
              if (node.isText && node.text) {
                const lines = node.text.split("\n")
                let currentPos = pos

                lines.forEach((line) => {
                  const result = handleLineDecorations(
                    line,
                    currentPos,
                    insideBlock
                  )
                  decorations = decorations.concat(result.decorations)
                  insideBlock = result.insideBlock

                  // Handle [[ ]] brackets
                  let validateMatch
                  while ((validateMatch = propertyRegex.exec(line)) !== null) {
                    const startValidate = currentPos + validateMatch.index
                    const endValidate = startValidate + validateMatch[0].length

                    decorations.push(
                      Decoration.inline(startValidate, endValidate, {
                        class: "highlight-validate",
                      })
                    )
                  }

                  // Handle << >> brackets
                  while (
                    (validateMatch = angleBracketRegex.exec(line)) !== null
                  ) {
                    const startValidate = currentPos + validateMatch.index
                    const endValidate = startValidate + validateMatch[0].length

                    decorations.push(
                      Decoration.inline(startValidate, endValidate, {
                        class: "highlight-validate",
                      })
                    )
                  }

                  currentPos += line.length + 1
                })
              }
            })

            decorations = handleRemarkLines(doc, decorations)

            return DecorationSet.create(state.doc, decorations)
          },
        },
      }),
    ]
  },
})

export default FontChangeExtension

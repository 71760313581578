import { Breadcrumb } from "antd"
import { useNavigate } from "react-router-dom"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"
/**
 * Renders the breadcrumb navigation for a project.
 *
 * This component displays breadcrumb links for navigating back to the home page
 * and to the current project.
 *
 * @component
 * @returns {JSX.Element} The rendered ProjectBreadcrumb component.
 */
const ProjectBreadcrumb = () => {
  const navigate = useNavigate()
  const project = useRecoilValue(projectAtom)

  return (
    <Breadcrumb
      items={[
        // Breadcrumb link for the home page
        {
          title: "Project",
          onClick: () => {
            navigate("/home")
          },
          className: "cursor-pointer",
        },
        // Breadcrumb link for the current project
        {
          title: project.Name,
          className: "cursor-pointer",
        },
      ]}
    />
  )
}

export default ProjectBreadcrumb

import axios from "axios"
import { ErrorNotification } from "../components/UI/Notifications/NotificationTemplate.component"

// Axios instance for non kinde api

const axiosInstance = () => {
  return axios.create({
    baseURL: import.meta.env.VITE_API_ROUTE,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

let useThisAxios = axiosInstance()

useThisAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    ErrorNotification(error.name, error.message)
  }
)
export default useThisAxios

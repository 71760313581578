import { useState, useEffect } from "react"
import { Layout } from "antd"
import { useLocation, Outlet } from "react-router-dom"

import Header from "layout/Nav/Header"
import SideMenu from "layout/Nav/SideMenu"
import { Loader } from "components/UI/Loader/Loader"

/**
 * Main component acts as a wrapper for all routes/pages.
 * It provides a common layout structure using Ant Design's Layout component.
 *
 * The Main component also manages the current base path from the URL to highlight
 * the correct menu item in the SideMenu. It ensures the base path is set correctly
 * even if the URL contains parameters (e.g., `/home/:id`).
 *
 * @function Main
 * @param {object} props - The props passed to the Main component.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the Main component.
 * @returns {React.ReactNode} The rendered Main component.
 */
const Main = (props) => {
  const location = useLocation()
  const { Content } = Layout
  const { pathname } = location

  const [currentBasePath, setCurrentBasePath] = useState("/home")

  useEffect(() => {
    const basePath = pathname.split("/")[1]
      ? `/${pathname.split("/")[1]}`
      : pathname
    if (currentBasePath !== basePath) {
      setCurrentBasePath(basePath)
    }
  }, [pathname, currentBasePath])

  if (currentBasePath !== `/${pathname.split("/")[1]}`) {
    return (
      <div className="absolute left-2/4 top-2/4">
        <Loader fontSize={48} color="#36C3ED" />
      </div>
    )
  }

  return (
    <>
      <Layout style={{height:"100vh"}} className="main-layout">
        <Header />
        <Layout className="site-layout">
          <SideMenu current={currentBasePath} />
          <Layout>
            <Content className="site-layout-background overflow-auto bg-gray-100">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default Main

import { useRef, useEffect, useState } from "react"
import { Loader } from "components/UI/Loader/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import DataQButton from "components/UI/Buttons/DataQButton"
import ExportModal from "components/UI/ExportModal/ExportModal"

/**
 * PreviewQuestionnaire component renders a preview of the questionnaire within an iframe and allows the user to export the content to HTML.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isConvertingToWordPreview - Indicates if the preview is being generated.
 * @param {string} props.previewContent - The HTML content to be displayed in the preview.
 * @param {Function} props.downloadHTML - Function to download the HTML content.
 * @returns {JSX.Element} The rendered PreviewQuestionnaire component.
 */
const PreviewQuestionnaire = ({
  isConvertingToWordPreview,
  previewContent,
  downloadHTML,
}) => {
  const iframeRef = useRef(null)
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)

  useEffect(() => {
    if (!isConvertingToWordPreview && iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(previewContent)
      iframeDoc.close()
    }
  }, [isConvertingToWordPreview, previewContent])

  /**
   * Handles the confirmation of the export action.
   *
   * @param {string} filename - The name of the file to be exported.
   */
  const confirmExport = (filename) => {
    downloadHTML(filename)
    setIsExportModalVisible(false)
  }

  return (
    <div className="flex flex-col w-full h-full">
      <ExportModal
        title="Export to HTML"
        confirm={confirmExport}
        cancel={setIsExportModalVisible}
        isVisible={isExportModalVisible}
        message={"Please enter a filename for your export"}
      />
      {!isConvertingToWordPreview ? (
        <div className="flex flex-col h-full">
          <div className="flex flex-row h-[48px] shadow-sm w-full p-2">
            <DataQButton
              onClick={() => setIsExportModalVisible(true)}
              className="w-7 !h-7 !p-0 ml-auto"
              type="primary"
            >
              <FontAwesomeIcon icon={faDownload} />
            </DataQButton>
          </div>
          <iframe
            ref={iframeRef}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="Preview"
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader fontSize={48} color="#36C3ED" />
          Generating preview...
        </div>
      )}
    </div>
  )
}

export default PreviewQuestionnaire

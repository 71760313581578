import { useEffect, useState, useRef, useCallback } from "react"
import Split from "react-split"
import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"
import { useQuestionnaire } from "hooks/useQuestionnaire"
import { useEngine } from "hooks/useEngine"
import PreviewQuestionnaire from "components/Home/QuestionnaireEditor/Preview/PreviewQuestionnaire.component"
import VariantsMenuBar from "components/Home/QuestionnaireEditor/Variant/VariantsMenuBar.component"
import { Loader } from "components/UI/Loader/Loader"
import Editor from "components/Home/QuestionnaireEditor/Editor/Editor"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"
import {
  parseQuestionnaireVariant,
  updateQuestionnaireWithVariant,
} from "utils/variantUtils"
import { escapeHTML } from "utils/escapeHtml"
import handleSequenceError from "utils/handleSequenceError"

/**
 * `QuestionnaireEditorContainer` is a component that manages the questionnaire editing interface.
 * It includes an editor, a preview pane, and a variant management toolbar.
 *
 * @component
 * @returns {JSX.Element} The rendered `QuestionnaireEditorContainer` component.
 */
const QuestionnaireEditorContainer = () => {
  const project = useRecoilValue(projectAtom)
  const {
    getQuestionnaireByID,
    isLoadingQuestionnaire,
    saveQuestionnaireByID,
    lockQuestionnaireByID,
  } = useQuestionnaire()
  const { convertToWordPreview, isConvertingToWordPreview, exportToHTML } =
    useEngine()
  const [session, setSession] = useState("") // Questionnaire session ID for locking

  const [questionnaire, setQuestionnaire] = useState("")
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewContent, setPreviewContent] = useState("")
  const [variants, setVariants] = useState([])
  const [selectedVariant, setSelectedVariant] = useState({})
  const [fullQuestionnaire, setFullQuestionnaire] = useState("")
  const [masterQuestionnaire, setMasterQuestionnaire] = useState("")
  const [variantContent, setVariantContent] = useState("")
  const editorRef = useRef(null)

  const isLocked = session === "" ? true : false

  const inMaster =
    Object.keys(selectedVariant).length === 0 &&
    selectedVariant.constructor === Object

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedVariant.name) {
        await getQuestionnaire(project.id)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant])

  // Lock the questionnaire when the component mounts
  useEffect(() => {
    lockQuestionnaire()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Locks the questionnaire to prevent concurrent edits by multiple users.
   *
   * @async
   * @function lockQuestionnaire
   * @returns {Promise<void>} Resolves when the questionnaire is successfully locked.
   */
  const lockQuestionnaire = async () => {
    const lockResponse = await lockQuestionnaireByID(
      project.id,
      project.Created_by
    )
    if (lockResponse.result && lockResponse.result.session) {
      setSession(lockResponse.result.session)
    } else if (lockResponse.result && lockResponse.result.fail) {
      ErrorNotification(
        "Questionnaire is being edited!",
        lockResponse.result.fail
      )
    }
  }

  /**
   * Fetches the questionnaire by ID and processes its content to extract master and variant data.
   *
   * @async
   * @function getQuestionnaire
   * @param {string} id - The ID of the questionnaire.
   * @returns {Promise<void>} Resolves when the questionnaire is successfully fetched and processed.
   */
  const getQuestionnaire = async (id) => {
    try {
      const qnr = await getQuestionnaireByID(id, project.Created_by)
      if (!qnr) {
        setQuestionnaire("")
        setFullQuestionnaire("")
      } else {
        const fullContent = qnr
        setFullQuestionnaire(fullContent)
        const parsedVariants = parseQuestionnaireVariant(qnr)
        setVariants(parsedVariants)

        const masterContentLines = []
        let insideVariant = false

        qnr.split("\n").forEach((line) => {
          if (line.startsWith("#DEFINE VARIANT")) {
            insideVariant = true
          } else if (insideVariant && line.trim() === "") {
            return
          } else if (insideVariant && !line.startsWith("#")) {
            return
          } else if (!insideVariant) {
            masterContentLines.push(line)
          }
        })

        const masterContent = masterContentLines.join("\n")
        setMasterQuestionnaire(masterContent)
        setQuestionnaire(
          masterContent
            .split("\n")
            .map((s) =>
              s.trim() === "" ? "<p></p>" : "<p>" + escapeHTML(s) + "</p>"
            )
            .join("")
        )
      }
    } catch (error) {
      console.error("Error Fetching Data:", error.message)
    }
  }

  /**
   * Saves the current state of the questionnaire, including any selected variant.
   *
   * @async
   * @function saveQuestionnaire
   * @returns {Promise<void>} Resolves when the questionnaire is successfully saved.
   */
  const saveQuestionnaire = useCallback(async () => {
    if (editorRef.current) {
      const editorHTML = editorRef.current.getHTML()
      const text = editorHTML.replace(/<p>/g, "").replace(/<\/p>/g, "\n").trim()

      const unescapedText = text
        .replace(/&lt;&lt;/g, "<<")
        .replace(/&gt;&gt;/g, ">>")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")

      let updatedQuestionnaire = masterQuestionnaire

      if (selectedVariant.name) {
        updatedQuestionnaire = updateQuestionnaireWithVariant(
          updatedQuestionnaire,
          selectedVariant.name,
          unescapedText,
          selectedVariant.languages
        )
      } else {
        updatedQuestionnaire = unescapedText.trim()
      }

      const variantOrder = {}
      variants.forEach((variant, index) => {
        variantOrder[variant.name] = index
        if (variant.name !== selectedVariant.name && variant.content) {
          updatedQuestionnaire = updateQuestionnaireWithVariant(
            updatedQuestionnaire,
            variant.name,
            variant.content,
            variant.languages
          )
        }
      })

      const parsedVariants = parseQuestionnaireVariant(updatedQuestionnaire)
      parsedVariants.sort((a, b) => variantOrder[a.name] - variantOrder[b.name])

      updatedQuestionnaire = parsedVariants.reduce(
        (acc, variant) =>
          updateQuestionnaireWithVariant(
            acc,
            variant.name,
            variant.content,
            variant.languages
          ),
        updatedQuestionnaire
      )

      try {
        const saveRes = await saveQuestionnaireByID(
          project.id,
          updatedQuestionnaire,
          project.Created_by,
          session
        )
        setFullQuestionnaire(updatedQuestionnaire)
        setVariants(parsedVariants)
        handleSequenceError(saveRes, "Error Saving Questionnaire!")
      } catch (error) {
        console.error("Error Saving Data:", error.message)
      }
    }
  }, [
    masterQuestionnaire,
    selectedVariant,
    variants,
    project.id,
    project.Created_by,
    session,
    saveQuestionnaireByID,
  ])

  /**
   * Handles the deletion of a variant from the questionnaire.
   *
   * @async
   * @function handleDeleteVariant
   * @param {Object} variant - The variant object to be deleted.
   * @returns {Promise<void>} Resolves when the variant is successfully deleted.
   */
  const handleDeleteVariant = async (variant) => {
    try {
      if (!fullQuestionnaire) {
        return
      }
      let questionnaireLines = fullQuestionnaire.split("\n")
      let insideVariant = false
      let updatedQuestionnaireLines = []

      questionnaireLines.forEach((line) => {
        if (line.startsWith(`#DEFINE VARIANT ${variant.name}`)) {
          insideVariant = true
          return
        }
        if (
          insideVariant &&
          (line.startsWith("#DEFINE VARIANT") || line.trim() === "")
        ) {
          insideVariant = false
        }
        if (!insideVariant) {
          updatedQuestionnaireLines.push(line)
        }
      })

      const updatedQuestionnaire = updatedQuestionnaireLines.join("\n").trim()
      const updatedVariants = variants.filter((v) => v.name !== variant.name)

      setVariants(updatedVariants)
      setFullQuestionnaire(updatedQuestionnaire)
      setMasterQuestionnaire(updatedQuestionnaire)

      await saveQuestionnaireByID(
        project.id,
        updatedQuestionnaire,
        project.Created_by,
        session
      )
      setSelectedVariant({})
      setVariantContent("")
    } catch (error) {
      console.error("Error Deleting Variant:", error.message)
      ErrorNotification(
        "An unexpected error occurred.",
        "Please try again later."
      )
    }
  }

  /**
   * Toggles the preview mode, generating a preview of the questionnaire.
   *
   * @async
   * @function togglePreview
   * @returns {Promise<void>} Resolves when the preview is successfully toggled.
   */
  const togglePreview = async () => {
    let options

    // Check if there are variants in the separate variants array
    if (variants && variants.length > 0) {
      // If variants exist but selectedVariant is undefined or doesn't have a name, show an error
      if (!selectedVariant || selectedVariant.name === undefined) {
        ErrorNotification(
          "Can't Generate Preview For Master If Variants Exist!!",
          "Please select a variant to generate a preview."
        )
        return
      }

      // If selectedVariant and languages exist, proceed to generate preview for the variant
      if (selectedVariant.languages && selectedVariant.languages.length > 0) {
        options = {
          language: selectedVariant.languages[0],
          variant: selectedVariant.name,
        }
      }
    } else {
      // If no variants exist (variants array is empty) and selectedVariant.name is undefined, use the default "MASTER" option
      if (!selectedVariant || selectedVariant.name === undefined) {
        options = {
          language: "English",
          variant: "MASTER",
        }
      }
    }

    try {
      setPreviewVisible((prevVisible) => {
        const newPreviewVisible = !prevVisible
        if (!prevVisible) {
          ;(async () => {
            try {
              const res = await convertToWordPreview(project.id, options)
              if (res && res["Err msg"] && res["Err msg"].startsWith("Error")) {
                setPreviewContent(
                  "An error occurred while generating the preview."
                )
              } else {
                setPreviewContent(res)
              }
            } catch (error) {
              console.error("Error Saving Data:", error.message)
              setPreviewContent(
                "An error occurred while generating the preview."
              )
            }
          })()
        }
        return newPreviewVisible
      })
    } catch (error) {
      console.error("Error during togglePreview:", error.message)
      ErrorNotification(
        "An unexpected error occurred.",
        "Please try again later."
      )
    }
  }

  /**
   * Downloads the questionnaire as an HTML file.
   *
   * @async
   * @function downloadHTML
   * @param {string} filename - The name of the file to download.
   * @returns {Promise<void>} Resolves when the HTML file is successfully downloaded.
   */
  const downloadHTML = async (filename) => {
    try {
      let options = {}
      if (!selectedVariant || selectedVariant.name === undefined) {
        options = {
          language: "English",
          variant: "MASTER",
        }
      } else {
        options = {
          language: selectedVariant.languages[0],
          variant: selectedVariant.name,
        }
      }
      await exportToHTML(project.id, options, filename)
    } catch (error) {
      ErrorNotification("Error Downloading HTML!", "Please try again.")
    }
  }

  /**
   * Handles the selection of a variant.
   *
   * @function handleSelectVariant
   * @param {Object} variant - The selected variant object.
   */
  const handleSelectVariant = (variant) => {
    if (variant.name === "Master") {
      setSelectedVariant({})
      setVariantContent("")
    } else {
      setSelectedVariant(variant)
      if (variant.content) {
        setVariantContent(
          variant.content
            .split("\n")
            .map((s) => "<p>" + escapeHTML(s) + "</p>")
            .join("")
        )
      }
    }
  }

  /**
   * Returns the notes for the entire questionnaire.
   *
   * @function getQuestionnaireNotes
   * @returns {Array} Array of notes for the questionnaire.
   */
  const getQuestionnaireNotes = () => {
    const notes = []
    fullQuestionnaire.split("\n").forEach((line) => {
      // Check if the line starts with "NOTE" or "NOTE FOR"
      if (line.startsWith("NOTE")) {
        let note
        if (line.startsWith("NOTE FOR")) {
          note = line.split("NOTE FOR")[1].split(":")[0].trim()
        } else {
          note = line.split("NOTE")[1].split(":")[0].trim()
        }

        if (note) {
          notes.push(note)
        }
      }
    })

    return notes
  }

  /**
   * Gets the current content to display in the editor based on the selected variant.
   *
   * @function getEditorContent
   * @returns {string} The content to display in the editor.
   */
  const getEditorContent = () => {
    return selectedVariant.name ? variantContent : questionnaire
  }

  /**
   * Inserts text at the end of the editor's content.
   *
   * @function insertEditorText
   * @param {string} text - The text to insert.
   */
  const insertEditorText = (text) => {
    if (editorRef.current) {
      editorRef.current.insertContentAtEnd(text)
    }
  }

  if (isLoadingQuestionnaire || selectedVariant === null) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={48} color="#36C3ED" />
        Loading Questionnaire...
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <VariantsMenuBar
        variants={variants}
        setVariants={setVariants}
        selectedVariant={selectedVariant}
        setSelectedVariant={handleSelectVariant}
        togglePreview={togglePreview}
        refresh={getQuestionnaire}
        insertVariantText={insertEditorText}
        setPreviewVisible={setPreviewVisible}
        handleDeleteVariant={handleDeleteVariant}
        saveQuestionnaire={saveQuestionnaire}
      />
      <div className="flex flex-col flex-grow overflow-hidden">
        <Split
          className="flex flex-grow overflow-hidden"
          gutterSize={previewVisible ? 5 : 0}
          sizes={previewVisible ? [50, 50] : [111, 0]}
        >
          <Editor
            ref={editorRef}
            questionnaire={getEditorContent()}
            saveQuestionnaire={saveQuestionnaire}
            variants={variants}
            inMaster={inMaster}
            isLocked={isLocked}
            getQuestionnaireNotes={getQuestionnaireNotes}
          />
          {previewVisible ? (
            <PreviewQuestionnaire
              downloadHTML={downloadHTML}
              previewContent={previewContent}
              isConvertingToWordPreview={isConvertingToWordPreview}
            />
          ) : (
            <div className="hidden" />
          )}
        </Split>
      </div>
    </div>
  )
}

export default QuestionnaireEditorContainer

import { atom } from "recoil"

/**
 * Atom to manage the state of a project in the application.
 *
 * @constant
 * @type {RecoilState<Object>}
 * @default
 * {
 *   Id: "",
 *   "Design Language": "",
 *   Goal: "",
 *   Languages: [],
 *   Name: "",
 *   Status: "",
 *   Type: "",
 *   createdAt: "",
 *   createdBy: "",
 *   isArchived: false,
 * }
 */
export const projectAtom = atom({
  key: "projectAtom", // unique ID (with respect to other atoms/selectors)
  default: {
    Id: "",
    Designlanguage: "",
    Goal: "",
    Languages: [],
    Name: "",
    Status: "",
    Type: "",
    Created_at: "",
    Created_by: "",
  }, // default value (aka initial value)
})

import { List } from "antd"

/**
 * TemplateFiles component displays a list of available template files,
 * allowing the user to select a template for further actions.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.templateFiles - An object containing public and private template file arrays.
 * @param {Array<string>} props.templateFiles.public - Array of public template filenames.
 * @param {Array<string>} props.templateFiles.private - Array of private template filenames.
 * @param {Function} props.onItemClick - Callback function that is called when a template file is clicked.
 * @returns {JSX.Element} The rendered TemplateFiles component.
 */
const TemplateFiles = ({ templateFiles, onItemClick }) => {
  // Create objects with filename and type for each template
  const sharedTemplates = templateFiles.public.map((filename) => ({
    template: filename,
    type: "public",
  }))

  const unitTemplates = templateFiles.private.map((filename) => ({
    template: filename,
    type: "private",
  }))

  // Combine the two arrays into one
  const allTemplates = [...sharedTemplates, ...unitTemplates]

  return (
    <div className="flex flex-col">
      <List
        itemLayout="horizontal"
        dataSource={allTemplates}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            className="cursor-pointer hover:bg-gray-200 rounded-md border mb-1 shadow-sm"
            onClick={() => onItemClick(item)}
          >
            <List.Item.Meta
              className="p-2 text-center font-semibold"
              title={`${item.type} - ${item.template}`}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default TemplateFiles

import { useState } from "react"
import { Modal, Typography } from "antd"
import TemplateWizard from "./TemplateWizard.component"
import { Loader } from "components/UI/Loader/Loader"

/**
 * TemplatesModal component renders a modal for managing Data(Q) templates.
 * It displays a wizard for template selection and configuration or a loader
 * if the template files are still being loaded.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.qnrEmpty - Indicates if the questionnaire is empty.
 * @param {boolean} props.isTemplateModalVisible - Controls the visibility of the modal.
 * @param {Function} props.setTemplateModalVisible - Function to toggle the visibility of the modal.
 * @param {boolean} props.listingTemplateFiles - Indicates if the template files are being listed (loading state).
 * @param {Array} props.templateFiles - The list of template files available.
 * @param {Object} props.editor - The editor instance used to instert templates into editor.
 * @returns {JSX.Element} The rendered TemplatesModal component.
 */
const TemplatesModal = (props) => {
  const {
    inMaster,
    qnrEmpty,
    isTemplateModalVisible,
    setTemplateModalVisible,
    listingTemplateFiles,
    templateFiles,
    editor,
  } = props
  const { Title } = Typography

  // Logic to determine modal width based on currentStep
  const [currentStep, setCurrentStep] = useState(0) // Add state for currentStep
  const modalWidth = currentStep > 1 ? "70vw" : undefined // Default width if currentStep is 0 or 1

  return (
    <>
      <Modal
        className="template-modal"
        open={isTemplateModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
        width={modalWidth}
      >
        <Title style={{ marginBottom: "0.5em" }} level={4}>
          Data(Q) Templates
        </Title>
        {!listingTemplateFiles ? (
          <TemplateWizard
            inMaster={inMaster}
            qnrEmpty={qnrEmpty}
            templateFiles={templateFiles}
            setTemplateModalVisible={setTemplateModalVisible}
            editor={editor}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <Loader fontSize={24} color="#36C3ED" />
            Loading Templates...
          </div>
        )}
      </Modal>
    </>
  )
}

export default TemplatesModal

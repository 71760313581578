import axios from "./kindeRequest"

/**
 * Fetches the user profile information.
 *
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<Object>} The user profile data.
 * @throws Will throw an error if the request fails.
 */
export const fetchUser = async (accessToken) => {
  try {
    const res = await axios.get(
      "https://sequence.kinde.com/oauth2/user_profile",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )

    return res.data
  } catch (err) {
    throw err
  }
}

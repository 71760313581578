const columns = [
  {
    title: "Project Name",
    dataIndex: "metadata",
    key: 1,
    render: (metadata) => metadata.Name,
    sorter: (a, b) => a.metadata.Name.localeCompare(b.metadata.Name),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Type",
    dataIndex: "metadata",
    key: 2,
    render: (metadata) => metadata.Type,
  },
  {
    title: "Project Owner",
    dataIndex: "metadata",
    key: 3,
    render: (metadata) => metadata.Created_by,
  },
  {
    title: "Date Created",
    dataIndex: "metadata",
    key: 4,
    render: (metadata) => metadata.Created_at,
    sorter: (a, b) =>
      new Date(a.metadata.Created_at) - new Date(b.metadata.Created_at),
    sortDirections: ["ascend"],
  },
  {
    title: "Status",
    dataIndex: "metadata",
    key: 5,
    render: (metadata) => metadata.Status,
  },
]

export default columns

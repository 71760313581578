import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

/**
 * Loader component to display a loading spinner.
 * It uses Ant Design's Spin component with a custom loading icon.
 *
 * @function Loader
 * @param {object} props - The props passed to the Loader component.
 * @param {number} props.fontSize - The font size of the loading icon.
 * @param {string} props.color - The color of the loading icon.
 * @param {string} [props.margin] - The margin around the loading icon.
 * @param {("small"|"default"|"large")} [props.size="default"] - The size of the Spin component.
 * @returns {React.ReactNode} The rendered Loader component.
 */
export const Loader = (props) => {
  const LoaderIcon = (
    <LoadingOutlined
      style={{
        fontSize: props.fontSize,
        color: props.color,
        margin: props.margin,
      }}
      spin
    />
  )
  return (
    <Spin
      className="flex items-center justify-center"
      indicator={LoaderIcon}
      size={props.size}
      data-testid="loader-spin"
    />
  )
}

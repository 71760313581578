import { Form, Input, Select } from "antd"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import DataQButton from "components/UI/Buttons/DataQButton"

import { useProjects } from "hooks/useProjects"

import handleSequenceError from "utils/handleSequenceError"

/**
 * GeneralInformationTab component - Renders a form for editing general information of a project.
 *
 * This component displays a form populated with the current project's information, allowing the user
 * to edit and save changes. It utilizes Ant Design's Form component for UI consistency and validation.
 * The form includes fields for project name, goal, status, type, design language, and programming languages.
 * Upon submission, it calls the `updateUserProject` function with the updated project details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.refreshProject - Function to refresh the project details after an update.
 * @returns {JSX.Element} The GeneralInformationTab component, rendering the editable form.
 */
const GeneralInformationTab = (props) => {
  const { refreshProject } = props
  const [form] = Form.useForm()
  const { updateUserProject, isUpdatingProject } = useProjects()
  const project = useRecoilValue(projectAtom)

  /**
   * Handles form submission to save changes.
   *
   * This function is called when the form is submitted. It constructs a request body from the form values
   * and calls the `updateUserProject` API function. If the update is successful, it shows a success notification
   * and refreshes the project details. If an error occurs, it shows an error notification.
   *
   * @async
   * @function
   * @param {Object} formValues - The values of the form fields.
   */
  const saveChanges = async (formValues) => {
    const body = {
      id: project.id,
      name: formValues.Name,
      goal: formValues.Goal,
      status: formValues.Status,
      type: formValues.Type,
      designLanguage: formValues.Designlanguage,
      languages: formValues.languages,
    }

    try {
      const res = await updateUserProject(body)
      if (handleSequenceError(res, "Error Updating Project!")) {
        SuccessNotification(
          "Project Updated Successfully!",
          "The project has been updated successfully."
        )
        refreshProject()
      }
    } catch (error) {
      ErrorNotification("Error Updating Project!", error.message)
    }
  }

  return (
    <div className="update-project-form bg-white rounded-b-2xl h-full overflow-auto">
      <Form
        form={form}
        name="update-project-form"
        onFinish={saveChanges}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        className="p-5 h-full flex flex-col"
        requiredMark={false}
        initialValues={{
          languages: project.Languages,
          Name: project.Name,
          Designlanguage: project.Designlanguage,
          Goal: project.Goal,
          Status: project.Status,
          Type: project.Type,
        }}
      >
        <Form.Item
          style={{ marginBottom: "2em" }}
          label="Project Name"
          validateTrigger="onSubmit"
          name="Name"
          rules={[
            {
              required: true,
              message: "Please Enter a Name",
            },
          ]}
        >
          <Input size="large" label="Project Name" placeholder="Project Name" />
        </Form.Item>
        <Form.Item name="Status" label="Status" style={{ marginBottom: "2em" }}>
          <Input size="large" label="Status" placeholder="Project status" />
        </Form.Item>
        <Form.Item label="Type" style={{ marginBottom: "2em" }} name="Type">
          <Input size="large" label="Type" placeholder="Project type" />
        </Form.Item>
        <Form.Item
          label="Design Language"
          style={{ marginBottom: "2em" }}
          validateTrigger="onSubmit"
          name="Designlanguage"
          rules={[
            {
              required: true,
              message: "Please Enter a Design Language",
            },
          ]}
        >
          <Input size="large" label="Design Language" />
        </Form.Item>
        <Form.Item
          name="languages"
          label="Languages"
          style={{ marginBottom: "2em" }}
        >
          <Select
            size="large"
            mode="tags"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Project Goal"
          style={{ marginBottom: "1em" }}
          validateTrigger="onSubmit"
          name="Goal"
          rules={[
            {
              required: false,
              message: "Please enter a project goal",
            },
          ]}
        >
          <Input.TextArea
            size="large"
            label="Project Goal"
            placeholder="Enter Project Description..."
            rows={4}
            style={{
              resize: "none",
              borderRadius: "8px",
              fontWeight: "500",
              fontSize: "16px",
            }}
          />
        </Form.Item>
        <div className="flex flex-row mt-auto">
          <div className="ml-auto mr-2 space-x-2">
            <DataQButton
              type="primary"
              htmlType="submit"
              isLoading={isUpdatingProject}
            >
              Save Changes
            </DataQButton>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default GeneralInformationTab

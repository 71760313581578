import React from "react"
import { createRoot } from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { RecoilRoot } from "recoil"
import RecoilNexus from "recoil-nexus"
import { KindeProvider } from "@kinde-oss/kinde-auth-react"
import * as Sentry from "@sentry/react"

import ProtectedRoutes from "utils/ProtectedRoutes"
import Main from "templates/Main"
import Home from "routes/Home"
import Login from "routes/Login"
import ProjectDetails from "routes/Home/$id"
import Superadmin from "routes/Superadmin"

// Style Imports - Add stylesheet from styles folder here
import "styles/global.css"
import "styles/dataq-button.css"
import "styles/editor.css"
import "styles/syntax-highlight.css"

Sentry.init({
  dsn: "https://71efc190831a149b601c920b3906b82a@o4507856015458304.ingest.de.sentry.io/4507856018210896",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
/**
 * Sets up the router configuration.
 */
const router = createBrowserRouter([
  {
    element: <ProtectedRoutes />,
    children: [
      {
        element: <Main />,
        children: [
          {
            path: "/home",
            element: <Home />,
          },
          {
            path: "/home/:id",
            element: <ProjectDetails />,
          },
          {
            path: "/superadmin",
            element: <Superadmin />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <Login />,
  },
])

/**
 * Renders the root React element into the DOM.
 */
const root = createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <KindeProvider
      clientId="9e1c8c527be2402e8876a194266f15ed"
      domain="https://sequence.kinde.com"
      redirectUri={import.meta.env.VITE_KINDE_REDIRECT}
      logoutUri={import.meta.env.VITE_KINDE_LOGOUT}
    >
      <RecoilRoot>
        <RecoilNexus />
        <RouterProvider router={router} />
      </RecoilRoot>
    </KindeProvider>
  </React.StrictMode>
)

import useApiCall from "hooks/useApiCall"
import { fetchUnitUsers } from "api/Repos/users"

/**
 * Custom hook `useDocument` for uploading documents.
 *
 * This hook encapsulates the logic for asynchronously uploading documents to a server.
 * It leverages the `useApiCall` hook for managing the API calls and loading states.
 *
 * @returns {Object} - An object containing the `uploadDocument` function and its loading state.
 *
 * @example
 * const { uploadDocument, isUploading } = useDocument();
 * const handleUpload = async (file) => {
 *   const response = await uploadDocument('document_id', file.name, file);
 *   console.log(response);
 * };
 */
export const useUnits = () => {
  const [getUnitUsers, isLoadingUnitUsers] = useApiCall(fetchUnitUsers)

  return { getUnitUsers, isLoadingUnitUsers }
}

import React from "react"

/**
 * StatisticsTable component to render a table of data, with optional subheaders.
 * It renders rows and columns dynamically based on the transformed data provided.
 *
 * @param {Object} props
 * @param {Object} props.transformedData - The transformed data object containing headers, rows, and subheaders if applicable.
 * @param {Array} props.transformedData.headers - An array of header objects, each with optional subHeaders.
 * @param {Array} props.transformedData.rows - An array of row data, where each row is an array of cell values.
 * @param {boolean} props.transformedData.hasSubHeaders - Boolean flag indicating if the table has subheaders.
 * @returns {JSX.Element} A styled table displaying the provided data.
 */
const StatisticsTable = ({ transformedData }) => {
  const { headers, rows, hasSubHeaders } = transformedData

  return (
    <div style={{ overflowX: "auto" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "auto",
        }}
      >
        <thead>
          <tr>
            {/* Shift headers one cell to the right for tables with subheaders */}
            {hasSubHeaders && <th></th>}
            {/* Render the "Statistic" column for tables with subheaders only */}
            {hasSubHeaders && (
              <th
                rowSpan={2} // Span two rows if subheaders exist
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  minWidth: "150px",
                  textAlign: "center",
                  backgroundColor: "#f0f0f0",
                }}
              >
                Statistic
              </th>
            )}
            {/* Render the main headers */}
            {headers.map((group, groupIndex) => (
              <th
                colSpan={
                  group.subHeaders && group.subHeaders.length > 0
                    ? group.subHeaders.length
                    : 1
                }
                key={groupIndex}
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  textAlign: "center",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {group.header || ""}
              </th>
            ))}
          </tr>

          {/* Subheaders row if subheaders exist */}
          {hasSubHeaders && (
            <tr>
              <th></th> {/* Empty cell for alignment */}
              {headers.map((group, groupIndex) =>
                group.subHeaders.map((subHeader, subHeaderIndex) => (
                  <th
                    key={`${groupIndex}-${subHeaderIndex}`}
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      minWidth: "150px",
                      textAlign: "center",
                      backgroundColor: "#f7f7f7",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {subHeader}
                  </th>
                ))
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* First cell for side headers like "UK", "US", etc., or row labels */}
              <th
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  textAlign: "center",
                  minWidth: "150px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {row[0] || ""} {/* Render side header or keep it empty */}
              </th>
              {/* Render row data starting from the second cell */}
              {row.slice(1).map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                    minWidth: "150px",
                    backgroundColor: "#ffffff",
                    whiteSpace: "nowrap",
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default StatisticsTable

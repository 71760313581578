import { useKindeAuth } from "@kinde-oss/kinde-auth-react"

import ProjectTable from "./ProjectTable/ProjectTable"
import AddProjectModal from "./CreateProject/AddProjectModal.component"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * Component for displaying projects.
 * It renders a ProjectTable component to show a list of projects and an AddProjectModal component to add new projects.
 * The AddProjectModal component is provided with a refresh function to update the project list upon adding a new project.
 *
 * @function ProjectDisplay
 * @param {object} props - The props passed to the ProjectDisplay component.
 * @param {Array} props.projects - The list of projects to display.
 * @param {Function} props.getProjects - A function to refresh the list of projects. This is passed to the AddProjectModal.
 * @returns {React.ReactNode} The rendered ProjectDisplay component, including the AddProjectModal and ProjectTable components.
 */
const ProjectDisplay = (props) => {
  const { filter, setFilter, getProjects, isLoading, projects } = props
  const { getClaim } = useKindeAuth()
  const userRoles = getClaim("roles")
  const isAdmin =
    userRoles?.value?.some(
      (role) => role.key === "admin" || role.key === "super-admin"
    ) || false

  const toggleFilter = () => {
    setFilter(filter === "owned" ? "member" : "owned")
  }

  const viewAllProjects = () => {
    setFilter("all")
  }

  // Sort the projects by date in descending order
  const sortedProjects = [...projects].sort(
    (a, b) => new Date(b.metadata.Created_at) - new Date(a.metadata.Created_at)
  )

  return (
    <div className="flex flex-col p-5">
      <div className="flex flex-row">
        <h1 className="text-3xl font-semibold m-0">
          Projects - {filter.charAt(0).toUpperCase() + filter.slice(1)}
        </h1>
        <div className="mb-5 ml-auto space-x-2">
          <DataQButton type="primary" onClick={toggleFilter}>
            {filter === "owned"
              ? "View Member Projects"
              : "View Owned Projects"}
          </DataQButton>
          {isAdmin && (
            <DataQButton type="secondary" onClick={viewAllProjects}>
              View All Projects
            </DataQButton>
          )}
          <AddProjectModal refresh={getProjects} />
        </div>
      </div>
      <ProjectTable isLoading={isLoading} projects={sortedProjects} />
    </div>
  )
}

export default ProjectDisplay

import { useState } from "react"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import { useSetRecoilState } from "recoil"
import { userAtom } from "atoms/userAtom"
import { fetchUser } from "api/Kinde/users"

/**
 * Custom hook for making API calls with loading state management.
 *
 * @param {Function} apiCall - The API call function to be executed.
 * @returns {[Function, boolean]} - A tuple containing the API call function and the loading state.
 */
const useApiCall = (apiCall) => {
  const [isLoading, setIsLoading] = useState(false)
  const { getToken, getClaim } = useKindeAuth()
  const setUser = useSetRecoilState(userAtom)

  /**
   * Executes the API call.
   *
   * @async
   * @function callApi
   * @returns {Promise<void>} - The result of the API call.
   */
  const callApi = async () => {
    setIsLoading(true)
    const token = await getToken()
    try {
      const res = await apiCall(token)
      const unit = getClaim("organizations", "id_token").value[0].name
      setUser({ ...res, unit })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching User:", error)
    }
  }

  return [callApi, isLoading]
}

/**
 * Custom hook to fetch and manage user data.
 *
 * This hook provides functionality to fetch user data from an API and manage loading state.
 * It uses Recoil for state management and Kinde for authentication.
 *
 * @returns {{ getUserData: Function, isLoadingUser: boolean }} - An object containing the `getUserData` function and the `isLoadingUser` boolean.
 *
 * @example
 * const { getUserData, isLoadingUser } = useUser();
 *
 * useEffect(() => {
 *   getUserData();
 * }, []);
 */
export const useUser = () => {
  const [getUserData, isLoadingUser] = useApiCall(fetchUser)

  return { getUserData, isLoadingUser }
}

import axios from "../request"
import { downloadFile } from "utils/downloadFile"

/**
 * Exports data as a JSON file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToJSON(
  token,
  user,
  unit,
  role,
  instanceID,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // Important to download files as binary
    }
    const response = await axios.get(
      `/export-json?instanceId=${instanceID}`,
      config
    )
    downloadFile(response, `${filename}.json` || "export.json")
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Exports data as a SQL file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} sqlType - The type of SQL export ("wide" or "narrow").
 * @param {string} project - The project name associated with the SQL export.
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToSQL(
  token,
  user,
  unit,
  role,
  instanceID,
  sqlType,
  project,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // Important to download files as binary
    }
    const response = await axios.get(
      `/export-sql?instanceId=${instanceID}&sqlType=${sqlType}&project=${project}`,
      config
    )
    downloadFile(response, `${filename}.sql` || "export.sql")
    return response
  } catch (error) {
    throw error
  }
}

/**
 * Exports data as an SPS file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} varType - The variable type to include in the export ("numbering" or "labels").
 * @param {string} spsType - The SPS type (e.g., "SPS" or "DAT").
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToSPS(
  token,
  user,
  unit,
  role,
  instanceID,
  varType,
  spsType,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "blob", // Important to download files as binary
    }
    const response = await axios.get(
      `/export-sps?instanceId=${instanceID}&varType=${varType}&spsType=${spsType}`,
      config
    )
    downloadFile(response, `${filename}.${spsType}` || `export.${spsType}`)
    return response
  } catch (error) {
    throw error
  }
}

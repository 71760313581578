import { List } from "antd"

import DataQButton from "components/UI/Buttons/DataQButton"
import { SuccessNotification } from "components/UI/Notifications/NotificationTemplate.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

import { useQueries } from "hooks/useQueries"

import handleSequenceError from "utils/handleSequenceError"
import { escapeHTML } from "utils/escapeHtml"
/**
 * SavedQueries component renders a list of saved queries.
 *
 * This component displays a list of saved queries that can be selected to load
 * their content into the editor or deleted.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project object.
 * @param {Function} props.toggleSaved - Function to toggle the view between saved queries and the editor.
 * @param {Object} props.editor - The editor instance from @tiptap/react.
 * @param {Array<string>} props.projectQueries - The list of saved queries for the project.
 * @param {Function} props.refreshQueries - Function to refresh the saved queries list.
 *
 * @returns {JSX.Element} The rendered SavedQueries component.
 */
const SavedQueries = ({
  project,
  toggleSaved,
  editor,
  projectQueries,
  refreshQueries,
}) => {
  const { getQuery, deleteQuery } = useQueries()
  /**
   * Handles the selection of a saved query.
   *
   * @param {React.MouseEvent} e - The click event.
   * @param {string} item - The selected query item.
   */
  const handleSelectSavedQuery = async (e, item) => {
    try {
      const queryRes = await getQuery(project.id, project.Created_by, item)
      toggleSaved()
      editor.commands.clearContent()

      const content = queryRes
        .split("\n")
        .map((line) => `<p>${escapeHTML(line)}</p>`) // Wrap each line in a <p> tag
        .join("") // Join the lines without extra newlines, as each line is now a paragraph

      editor.commands.insertContent(content)
    } catch (error) {
      console.error(`Error in API call:`, error)
      throw error
    }
  }

  /**
   * Handles the deletion of a saved query.
   *
   * @param {React.MouseEvent} e - The click event.
   * @param {string} item - The query item to be deleted.
   */
  const deleteSavedQuery = async (e, item) => {
    try {
      e.stopPropagation()
      const deleteRes = await deleteQuery(project.id, project.Created_by, item)
      if (handleSequenceError(deleteRes, "Error deleting query")) {
        SuccessNotification(
          "Query Deleted successfully!",
          "Query has been permanently deleted."
        )
        refreshQueries()
      }
    } catch (error) {
      console.error(`Error in API call:`, error)
      throw error
    }
  }

  return (
    <div className="flex flex-1 border mb-14">
      <List
        itemLayout="horizontal"
        className="w-full h-full overflow-y-auto"
        dataSource={projectQueries}
        renderItem={(item) => (
          <List.Item
            actions={[
              <DataQButton
                type="primary"
                className="mr-5"
                onClick={(e) => deleteSavedQuery(e, item)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </DataQButton>,
            ]}
            className="cursor-pointer hover:bg-gray-100"
            onClick={(e) => handleSelectSavedQuery(e, item)}
          >
            <List.Item.Meta className="p-3 px-5" title={item} />
          </List.Item>
        )}
      />
    </div>
  )
}

export default SavedQueries

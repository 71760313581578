import { atom } from "recoil"

/**
 * Atom to manage user state.
 *
 * This atom holds the state for user information including id, email, username, name, and other details.
 *
 * @type {import('recoil').RecoilState<Object>}
 * @property {string} id - The user's unique identifier.
 * @property {string} preferred_email - The user's preferred email address.
 * @property {string} username - The user's username.
 * @property {string} provided_id - An additional identifier for the user.
 * @property {string} last_name - The user's last name.
 * @property {string} first_name - The user's first name.
 * @property {string} picture - The URL to the user's profile picture.
 * @property {string} unit - The user's unit or department.
 */
export const userAtom = atom({
  key: "userAtom", // unique ID (with respect to other atoms/selectors)
  default: {
    id: "",
    preferred_email: "",
    username: "",
    provided_id: "",
    last_name: "",
    first_name: "",
    picture: "",
    unit: "",
  }, // default value (aka initial value)
})

import { Table } from "antd"
import { useNavigate } from "react-router-dom"

import columns from "./columns"
import LoadingSkeleton from "components/UI/LoadingSkeleton/LoadingSkeleton"

/**
 * Renders a table component displaying project data.
 *
 * @param {object} props - The props object.
 * @param {Array} props.projects - An array of project data to display in the table.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @returns {JSX.Element} The project table component.
 */
const ProjectTable = (props) => {
  const { projects, isLoading } = props
  const navigate = useNavigate()

  // Ensure projects is always an array
  const dataSource = Array.isArray(projects) ? projects : []

  return (
    <Table
      size="small"
      className="project-table"
      rowClassName="cursor-pointer"
      rowKey={"id"}
      dataSource={isLoading ? [] : dataSource}
      columns={columns}
      pagination={{ pageSize: 15 }}
      locale={{
        emptyText: isLoading ? (
          <LoadingSkeleton type="table" columns={columns} />
        ) : (
          "No data"
        ),
      }}
      onRow={(record) => ({
        onClick: () => {
          if (record.id) {
            navigate(`/home/${record.id}`)
          }
        },
      })}
    />
  )
}

export default ProjectTable
